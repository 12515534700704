import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

class Content extends Component {

    constructor(props) {
        super(props);
        this.state = {
            categoryList: [],
            metalType: "",
            stoneList: [],
            designCategory: '',
            goldKerat: "",
            goldWeight: "",
            goldColor: "",
            wantDiamond: false,
            diamondQuality: "",
            diamondNo: "",
            diamondWeight: "",
            wantStone: "",
            stoneType: "",
            numberOfStone: "",
            stoneWeight: "",
            remarks: "",
            customImgOne: "",
            customImgTwo: "",
            customImgThree: "",
            customImgFour: "",
            fileInputRefs: [React.createRef(), React.createRef(), React.createRef(), React.createRef()]
        };
    }

    // componentDidMount() {
    //     this.setState({ ordersuccessPage: false });
    //     const { history } = this.props;
    //     const isLoggedIn = JSON.parse(localStorage.getItem('userData'));
    //     if (isLoggedIn) {
    //         this.setState({ userId: isLoggedIn.userId });
    //         this.fetchData(isLoggedIn.userId);
    //     } else {
    //         window.location.href = '/login';
    //     }

    // }

    // showIsCompanyDtl = () => {
    //     this.setState({ isCompany: !this.state.isCompany });
    //     this.handleCheckboxChange("Other");
    // }

    componentDidMount() {
        this.getCategoryList();
        this.getStoneList();
    }

    showGolddetail = () => {
        this.setState({ gquality: !this.state.gquality });
    }

    showDiamonddetail = () => {
        this.setState({ dquality: !this.state.dquality });
    }
    showStonedetail = () => {
        this.setState({ stonedtl: !this.state.stonedtl });
    }

    triggerFileInputClick = (index) => {
        this.state.fileInputRefs[index].current.click();
    }

    async getCategoryList() {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/category');
        const responseData = await response.json();

        this.setState({ categoryList: responseData });
    }

    async getStoneList() {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL + '/api/admin/other-stones');
        const responseData = await response.json();

        this.setState({ stoneList: responseData });
    }

    handleOptionChange = (event) => {
        this.setState({ goldKerat: event.target.value });
    };

    handleColorChange = (event) => {
        this.setState({ goldColor: event.target.value });
    };

    handleDiamondChange = (event) => {
        this.setState({ diamondQuality: event.target.value });
    };

    fileUpload(event, type) {
        if (type == 'customImgOne') {
            this.setState({ customImgOne: event.target.files[0] });
        } else if (type == 'customImgTwo') {
            this.setState({ customImgTwo: event.target.files[0] });
        } else if (type == 'customImgThree') {
            this.setState({ customImgThree: event.target.files[0] });
        } else if (type == 'customImgFour') {
            this.setState({ customImgFour: event.target.files[0] });
        }
    }

    emptyValue() {
        this.setState({
            metalType: "",
            designCategory: '',
            goldKerat: "",
            goldWeight: "",
            goldColor: "",
            wantDiamond: false,
            diamondQuality: "",
            diamondNo: "",
            diamondWeight: "",
            wantStone: "",
            stoneType: "",
            numberOfStone: "",
            stoneWeight: "",
            remarks: "",
            customImgOne: "",
            customImgTwo: "",
            customImgThree: "",
            customImgFour: "",
        })
    }

    async addCustomDesign() {

        const isLoggedIn = JSON.parse(localStorage.getItem('userData'));
        const formData = new FormData();
        formData.append("custMetalType", this.state.metalType);
        formData.append("custGoldQuality", this.state.goldKerat);
        formData.append("custMetalWeight", this.state.goldWeight);
        formData.append("custGoldColor", this.state.goldColor);
        formData.append("custCategory", this.state.designCategory);
        formData.append("custDiamond", this.state.wantDiamond);
        formData.append("custDiamondQuality", this.state.diamondQuality);
        formData.append("custDiamondNo", this.state.diamondNo);
        formData.append("custDiamondWeight", this.state.diamondWeight);
        formData.append("custStone", this.state.wantStone);
        formData.append("custStoneType", this.state.stoneType);
        formData.append("custStoneNo", this.state.numberOfStone);
        formData.append("custStoneWeight", this.state.stoneWeight);
        formData.append("addRemarks", this.state.remarks);
        formData.append("custImgOne", this.state.customImgOne);
        formData.append("custImgTwo", this.state.customImgTwo);
        formData.append("custImgThree", this.state.customImgThree);
        formData.append("custImgFour", this.state.customImgFour);
        formData.append("userId", isLoggedIn.userId);


        const response = await fetch(process.env.REACT_APP_API_BASE_URL + 'http://mj.loc/api/admin/cutom-jewelry/new-request', {
            method: 'POST',
            body: formData,
        });
        const responseData = await response.json();

        if (responseData.message == "Your Custom Order Requested Placed Successfully! Our Admin team will get back to you within 48Hrs") {
            this.setState({ successMessage: responseData.message, errorMessage: null });
            this.emptyValue();
        } else {
            this.setState({ errorMessage: responseData.message, successMessage: null });
        }
    }

    render() {
        if (this.state.ordersuccessPage) {
            return <Redirect to="/thank-you" />;
        }
        return (
            <section className="customize pt-120 pb-120">
                <div className="container justify-content-center">
                    <form>
                        <div className="col-12">
                            <div className="custom-form">
                                <h5 className='mb-3'>Start Customizing your Jewel.</h5>
                                <div className="row">
                                    <div className='col-xl-6 col-lg-6 col-sm-6 col-6 input-group input-group-two'>
                                        <label className='tag-label label col-12'>Metal
                                            <span className="text-danger">*</span>
                                        </label>
                                        <div className='w-100'>
                                            <select name="metal" value={this.state.metalType} onChange={(e) => this.setState({ metalType: e.target.value })}>
                                                <option selected>Select Metal</option>
                                                <option value="gold">Gold</option>
                                                <option value="silver">Silver</option>
                                                <option value="platinum">Platinum</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className='col-xl-6 col-lg- col-sm-6 col-6 input-group input-group-two'>
                                        <div className='label col-12'>
                                            <label className='tag-label'>Category
                                                <span className="text-danger">*</span>
                                            </label>
                                        </div>
                                        <div className='w-100'>
                                            <select className='w-100' name="metal" value={this.state.designCategory} onChange={(e) => this.setState({ designCategory: e.target.value })}>
                                                <option selected>Select Category</option>
                                                {this.state.categoryList.map((item, i) => (
                                                    <option value={item.pr_cat_name}>{item.pr_cat_name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {this.state.metalType == "gold" &&
                                        <div className='col-12 mt-5'>
                                            <div className='row'>


                                                {/* <div className='col-xl-6 col-lg-6 col-md-6 mt-4'>
                                                    <div className="input-group input-group-two mb-20">
                                                        <label>Expected Weight
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input type="text" placeholder="Gold Weight" name="customer_street" value={this.state.goldWeight} onChange={(e) => this.setState({ goldWeight: e.target.value })} />
                                                    </div>
                                                </div> */}

                                                <div className='col-6'>
                                                    <div class="check input-group">
                                                        <label className='tag-label'>Select Colour
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div className="color-sec mb-20">
                                                        <div className="color-box">
                                                            <label className="m-0">
                                                                <input type="radio" name="material" value="Yellow Gold" checked={this.state.goldColor === 'Yellow Gold'} onChange={this.handleColorChange} />
                                                                <span className="custom-color yellow yellow-gold choose-material">Yellow Gold</span>
                                                            </label>
                                                            <label className="m-0">
                                                                <input type="radio" name="material" value="Rose Gold" checked={this.state.goldColor === 'Rose Gold'} onChange={this.handleColorChange} />
                                                                <span className="custom-color rose rose-gold choose-material">Rose Gold</span>
                                                            </label>
                                                            <label className="m-0">
                                                                <input type="radio" name="material" value="White Gold" checked={this.state.goldColor === 'White Gold'} onChange={this.handleColorChange} />
                                                                <span className="custom-color whiteg white-gold choose-material">White Gold</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-6'>
                                                    <div class="check input-group">
                                                        <label className='tag-label'>Select Quality
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                    </div>
                                                    <div class="check input-group mt-1">
                                                    <div class="checkout-term ml-5">
                                                            <input class="radio" type="radio" name="gquality" id="gquality1" value="14" checked={this.state.goldKerat === '14'} onChange={this.handleOptionChange} />
                                                            <label class="input-group-two" for="gquality3">14kt</label>
                                                        </div>
                                                        <div class="checkout-term align-items-center">
                                                            <input class="radio" type="radio" name="gquality" id="gquality1" value="18" checked={this.state.goldKerat === '18'} onChange={this.handleOptionChange} />
                                                            <label class="input-group-two" for="gquality1">18kt</label>
                                                        </div>
                                                        <div class="checkout-term ml-5">
                                                            <input class="radio" type="radio" name="gquality" id="gquality1" value="22" checked={this.state.goldKerat === '22'} onChange={this.handleOptionChange} />
                                                            <label class="input-group-two" for="gquality2">22kt</label>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    }

                                    <div class="col-xl-3 col-lg-3 col-sm-6 col-6 check input-group mt-5">
                                        <div class="checkout-term align-items-center">
                                            <input class="radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => this.setState({ wantDiamond: e.target.value })} />
                                            <label class="check-label input-group-two" for="flexRadioDefault1">Want Diamond in Jewel</label>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-3 col-sm-6 col-6 check input-group mt-5">
                                        <div class="checkout-term align-items-center">
                                            <input class="radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" onChange={(e) => this.setState({ wantStone: e.target.value })} />
                                            <label class="check-label input-group-two" for="flexRadioDefault1">Want Stones in Jewel</label>
                                        </div>
                                    </div>
                                    {/* <div class="check input-group mt-5">
                                            <div class="checkout-term align-items-center">
                                                <input class="radio" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onChange={this.showGolddetail} />
                                                <label class="check-label input-group-two" for="flexRadioDefault1">Gold</label>
                                            </div>
                                            <div class="checkout-term ml-5">
                                                <input class="radio" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                                                <label class="input-group-two" for="flexRadioDefault2">Silver</label>
                                            </div>
                                            <div class="checkout-term ml-5">
                                                <input class="radio" type="radio" name="flexRadioDefault" id="flexRadioDefault3" />
                                                <label class="check-label input-group-two" for="flexRadioDefault3">Platinum</label>
                                            </div>
                                        </div> */}


                                    {/* <div className="col-xl-6 input-group input-group-two mb-20">
                                            <label>Company Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input type="text" placeholder="Company Name" name="customer_name" value={this.state.companyName} onChange={(e) => this.setState({ companyName: e.target.value })} required />
                                        </div>
                                        <div className='col-12 input-group input-group-two mb'>
                                            <label>Type</label>
                                            <span className="text-danger">*</span>
                                        </div>
                                        <div class="check input-group">
                                            <div className='checkout-term col-6'>
                                                <input class="check-box" type="checkbox" id="check1" name="option6" checked={this.state.selectedOption === "Other"} onChange={this.showIsCompanyDtl} />
                                                <label class="check-label input-group-two">Other</label>
                                            </div>
                                        </div> */}
                                </div>

                                {/* <div class="check input-group mt-5">
                                    <div class="checkout-term align-items-center">
                                        <input class="radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" onChange={this.showDiamonddetail} />
                                        <label class="check-label input-group-two" for="flexRadioDefault1">Want Diamond in Jewel</label>
                                    </div>
                                </div> */}
                                {/* {this.state.wantDiamond &&
                                    <div className='row'>
                                        <div className='col-12 mt-4'>
                                            <div class="check input-group">
                                                <label className='tag-label'>Select Quality
                                                    <span className="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div class="check input-group mt-1">
                                                <div class="checkout-term col-sm-3 col-6">
                                                    <input class="radio" type="radio" name="dquality" id="dquality1" value="VVS-EF" checked={this.state.diamondQuality === 'VVS-EF'} onChange={this.handleDiamondChange} />
                                                    <label class="input-group-two" for="dquality1">VVS-EF</label>
                                                </div>
                                                <div class="checkout-term col-sm-3 col-6">
                                                    <input class="radio dquality" type="radio" name="dquality" id="dquality2" value="VVS-GH" checked={this.state.diamondQuality === 'VVS-GH'} onChange={this.handleDiamondChange} />
                                                    <label class="input-group-two" for="dquality2">VVS-GH</label>
                                                </div>
                                                <div class="checkout-term col-sm-3 col-6">
                                                    <input class="radio dquality sigh" type="radio" name="dquality" id="dquality3" value="SI-GH" checked={this.state.diamondQuality === 'SI-GH'} onChange={this.handleDiamondChange} />
                                                    <label class="input-group-two" for="dquality3">SI-GH</label>
                                                </div>
                                                <div class="checkout-term col-sm-3 col-6">
                                                    <input class="radio dquality siij" type="radio" name="dquality" id="dquality4" value="SI-IJ" checked={this.state.diamondQuality === 'SI-IJ'} onChange={this.handleDiamondChange} />
                                                    <label class="input-group-two" for="dquality4">SI-IJ</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-md-6 mt-4'>
                                            <div className="input-group input-group-two mb-20">
                                                <label>No. of Diamonds
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input type="number" placeholder="Number of Diamonds" name="customer_street" value={this.state.diamondNo} onChange={(e) => this.setState({ diamondNo: e.target.value })} />
                                            </div>
                                        </div>

                                        <div className='col-xl-6 col-md-6 mt-4'>
                                            <div className="input-group input-group-two mb-20">
                                                <label>Expected Weight
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input type="number" placeholder="Diamond Weight" name="customer_street" value={this.state.diamondWeight} onChange={(e) => this.setState({ diamondWeight: e.target.value })} />
                                            </div>
                                        </div>
                                    </div>
                                } */}

                                {/* <div class="check input-group mt-5">
                                    <div class="checkout-term align-items-center">
                                        <input class="radio" type="checkbox" name="flexRadioDefault" id="flexRadioDefault1" onChange={this.showStonedetail} />
                                        <label class="check-label input-group-two" for="flexRadioDefault1">Want Stones in Jewel</label>
                                    </div>
                                </div> */}
                                {this.state.wantStone &&
                                    <div className='row'>
                                        <div className='col-12 mt-4'>
                                            <div class="check input-group">
                                                <label className='tag-label'>Select Stone
                                                    <span className="text-danger">*</span>
                                                </label>
                                            </div>
                                            <div className='input-group input-group-two w-100'>
                                                <select className='' value={this.state.stoneType} onChange={(e) => this.setState({ stoneType: e.target.value })}>
                                                    <option selected>Select Stone</option>
                                                    {this.state.stoneList.map((item, i) => (
                                                        <option value={item.ots_name}>{item.ots_name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>

                                        {/* <div className='col-xl-6 col-md-6 mt-4'>
                                            <div className="input-group input-group-two mb-20">
                                                <label>No. of Stones
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input type="number" placeholder="Number of Stones" name="customer_street" value={this.state.numberOfStone} onChange={(e) => this.setState({ numberOfStone: e.target.value })} />
                                            </div>
                                        </div> */}

                                        {/* <div className='col-xl-6 col-md-6 mt-4'>
                                            <div className="input-group input-group-two mb-20">
                                                <label>Expected Weight
                                                    <span className="text-danger">*</span>
                                                </label>
                                                <input type="number" placeholder="Stone Weight" name="customer_street" value={this.state.stoneWeight} onChange={(e) => this.setState({ stoneWeight: e.target.value })} />
                                            </div>
                                        </div> */}
                                    </div>
                                }

                                <div className='row'>
                                    <div className="col-12 input-group input-group-two mb-20 mt-5">
                                        <label>Additional Remarks
                                            <span className="text-danger">*</span>
                                        </label>
                                        <textarea className='input-group input-group-two' placeholder="Write Here..." rows="4" name="" value={this.state.remarks} onChange={(e) => this.setState({ remarks: e.target.value })}></textarea>
                                    </div>
                                </div>

                                {/* <div className='row'>
                                    <div className="col-12 input-group input-group-two mb-20 mt-5">
                                        <label>Upload Image
                                            <span className="text-danger">*</span>
                                        </label>
                                        <input className='' placeholder='Drag and drop' type="file" accept='image/*' id='droparea' name="customer_street" />
                                    </div>
                                </div> */}

                                <div className='dropzone'>
                                    <div className='input-group input-group-two'>
                                        <label>
                                            Upload Images
                                            <span className="text-danger">*</span>
                                        </label>
                                    </div>
                                    <div className='drop-sec'>
                                        <div className='row'>
                                            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <div className="drop-one input-group input-group-two mb-20">
                                                    <label for="drop" id='droparea' onClick={() => this.triggerFileInputClick(0)}>
                                                        <input className='' type="file" title=" " ref={this.state.fileInputRefs[0]} accept='image/*' onChange={(event) => this.fileUpload(event, 'customImgOne')} />
                                                        <div id='image-view'>
                                                            <i class="fa fa-upload"></i><br></br>
                                                            <span>Drag and drop or click to upload an image.</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <div className="drop-two input-group input-group-two mb-20">
                                                    <label for="drop" id='droparea' onClick={() => this.triggerFileInputClick(1)}>
                                                        <input className='' type="file" title=" " ref={this.state.fileInputRefs[1]} accept='image/*' onChange={(event) => this.fileUpload(event, 'customImgTwo')} />
                                                        <div id='image-view'>
                                                            <i class="fa fa-upload"></i><br></br>
                                                            <span>Drag and drop or click to upload an image.</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <div className="drop-three input-group input-group-two mb-20">
                                                    <label for="drop" id='droparea' onClick={() => this.triggerFileInputClick(2)}>
                                                        <input className='' type="file" title=" " ref={this.state.fileInputRefs[2]} accept='image/*' onChange={(event) => this.fileUpload(event, 'customImgThree')} />
                                                        <div id='image-view'>
                                                            <i class="fa fa-upload"></i><br></br>
                                                            <span>Drag and drop or click to upload an image.</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12'>
                                                <div className="drop-four input-group input-group-two mb-20">
                                                    <label for="drop" id='droparea' onClick={() => this.triggerFileInputClick(3)}>
                                                        <input className='' type="file" title=" " ref={this.state.fileInputRefs[3]} accept='image/*' onChange={(event) => this.fileUpload(event, 'customImgFour')} />
                                                        <div id='image-view'>
                                                            <i class="fa fa-upload"></i><br></br>
                                                            <span>Drag and drop or click to upload an image.</span>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex justify-content-center'>
                            <div className="sub-btn mt-5">
                                {/* <Link to="/thank-you"> */}
                                <button type="button" onClick={() => this.addCustomDesign()} className="main-btn btn-filled">Submit</button>
                                {/* </Link> */}
                            </div>
                        </div>

                        {this.state.successMessage && (
                            <div className="alert alert-success margin-top" role="alert">
                                {this.state.successMessage}
                            </div>
                        )}

                        {this.state.errorMessage && (
                            <div className="alert alert-danger" role="alert">
                                {this.state.errorMessage}
                            </div>
                        )}
                    </form>
                </div>
            </section>
        );
    }
}

export default Content;