import React, { Component } from 'react';
// import { Link } from 'react-router-dom';

class Pagination extends Component {
    render() {
      const { currentPage, totalPages, onPageChange, filterTypeData } = this.props;
      var pathName = "/allJewellery/";
      if(filterTypeData != ''){
        pathName = "/alljewellery/productfilter/" + filterTypeData
      } 
        
      // Ensure there are at least two pages
      if (totalPages <= 1) {
        return null;
      }
  
      const generatePageNumbers = () => {
        const pageNumbers = [];
        const delta = 2; // Number of pages to show around the current page
  
        const range = {
          start: Math.max(2, currentPage - delta),
          end: Math.min(totalPages - 1, currentPage + delta)
        };
  
        // Adding first page and ellipsis if necessary
        if (range.start > 2) {
          pageNumbers.push(1, '...');
        } else {
          for (let i = 1; i < range.start; i++) {
            pageNumbers.push(i);
          }
        }
  
        // Adding pages around the current page
        for (let i = range.start; i <= range.end; i++) {
          pageNumbers.push(i);
        }
  
        // Adding last page and ellipsis if necessary
        if (range.end < totalPages - 1) {
          pageNumbers.push('...', totalPages);
        } else {
          for (let i = range.end + 1; i <= totalPages; i++) {
            pageNumbers.push(i);
          }
        }
  
        return pageNumbers;
      };
  
      const pageNumbers = generatePageNumbers();
  
      return (
        <ul className="pagination">
          <li className='cusorPointer'>
            <div
            className='paginationDesign'
            //   to={pathName + (currentPage - 1)}
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              <i className="far fa-angle-double-left" />
            </div>
          </li>
          {pageNumbers.map((number, index) => (
            <li
              key={index}
              className={currentPage === number ? 'cusorPointer active' : 'cusorPointer'}
            >
              {number === '...' ? (
                <span>...</span>
              ) : (
                <div
                className={currentPage === number ? 'paginationDesignActive':'paginationDesign'}
                //   to={pathName + number}
                  onClick={() => onPageChange(number)}
                >
                  {number}
                </div>
              )}
            </li>
          ))}
          <li className='cusorPointer'>
            <div
            className='paginationDesign'
            //   to={pathName + (currentPage + 1)}
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              <i className="far fa-angle-double-right" />
            </div>
          </li>
        </ul>
      );
    }
  }
  
  export default Pagination;
  
