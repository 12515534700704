import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Headertwo';
import Breadcrumb from '../layouts/Breadcrumbs';
import Instafeeds from '../layouts/Instafeeds';
import Footer from '../layouts/Footertwo';
import Content from '../sections/shopdetail/Content';

class Shopdetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: 0
        };
    }

    countIncrease() {
        this.setState({ counter: this.state.counter + 1 });
    }
    render() {
        return (
            <Fragment>
                <MetaTags>
                    <title>Milli | Product Detail</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Header counterValue={this.state.counter} />
                <Breadcrumb breadcrumb={{ pagename: 'Product Detail' }} />
                <Content counterIncrease={() => this.countIncrease()} />
                <Instafeeds />
                <Footer />
            </Fragment>
        );
    }
}

export default Shopdetail;