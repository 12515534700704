import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        addCartList(state, action){   
            // Clear existing data
            state.splice(0, state.length);
            action.payload.forEach(element => {
                state.push(element);
            });
        }
    }
});

export const { addCartList } = customerSlice.actions;
export default customerSlice.reducer;