import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

class Content extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalPrice: 0,
            isCompany: false,
            userId: 0,
            ordersuccessPage: false,
            firstName: '',
            lastName: '',
            country: 'India',
            stAddress1: '',
            stAddress2: '',
            city: '',
            state: '',
            pincode: '',
            countryCode: '91',
            phoneNumber: '',
            email: '',
            companyName: '',
            companyEmail: '',
            gstin: '',
            termConditionAccept: false,
            ownerDetail1: {
                name: '',
                mobileNo: '',
                email: '',
                birthDate: '',
                anniversaryDate: ''
            },
            ownerDetail2: {
                name: '',
                mobileNo: '',
                email: '',
                birthDate: '',
                anniversaryDate: ''
            },
            ownerDetail3: {
                name: '',
                mobileNo: '',
                email: '',
                birthDate: '',
                anniversaryDate: ''
            },
            showRoomDtl: {
                name: '',
                areaName: '',
                dateOfEstd: ''
            },
            showRoomAddress: {
                streetAddress: '',
                streetAddressL2: '',
                city: '',
                state: '',
                postalCode: ''
            },
            management: {
                name: '',
                mobileNo: '',
                email: ''
            },
            orderRepair: {
                name: '',
                mobileNo: '',
                email: ''
            },
            logistics: {
                name: '',
                mobileNo: '',
                email: ''
            },
            accountManager: {
                name: '',
                mobileNo: '',
                email: ''
            },
            accountDetails: {
                companyPanNo: '',
                gstNo: '',
                nameOnInvoice: '',
                wefDtl: '',
                bankName: '',
                accountNo: '',
                branch: '',
                ifscCode: ''
            },
            curentOprStatus: {
                noOfRetails: '',
                majorLocation: ''
            },
            fastMovingCategory: {
                bangles: '',
                bracelets: '',
                earrings: '',
                fingerRings: '',
                necklace: '',
                nosePins: '',
                pendants: ''
            },
            typeOfRegis: 'Reseller',
            selectedOption: '',
            otherTypeData: '',
            successMessage: ''
        };
    }

    componentDidMount() {
        // this.setState({ ordersuccessPage: false });
        // const { history } = this.props;
        // const isLoggedIn = JSON.parse(localStorage.getItem('userData'));
        // if (isLoggedIn) {
        //     this.setState({ userId: isLoggedIn.userId });
        //     this.fetchData(isLoggedIn.userId);
        // } else {
        //     window.location.href = '/login';
        // }

    }

    emptyValue =()=>{
        this.setState({ data: [],
            totalPrice: 0,
            isCompany: false,
            userId: 0,
            ordersuccessPage: false,
            firstName: '',
            lastName: '',
            country: 'India',
            stAddress1: '',
            stAddress2: '',
            city: '',
            state: '',
            pincode: '',
            countryCode: '91',
            phoneNumber: '',
            email: '',
            companyName: '',
            companyEmail: '',
            gstin: '',
            termConditionAccept: false,
            ownerDetail1: {
                name: '',
                mobileNo: '',
                email: '',
                birthDate: '',
                anniversaryDate: ''
            },
            ownerDetail2: {
                name: '',
                mobileNo: '',
                email: '',
                birthDate: '',
                anniversaryDate: ''
            },
            ownerDetail3: {
                name: '',
                mobileNo: '',
                email: '',
                birthDate: '',
                anniversaryDate: ''
            },
            showRoomDtl: {
                name: '',
                areaName: '',
                dateOfEstd: ''
            },
            showRoomAddress: {
                streetAddress: '',
                streetAddressL2: '',
                city: '',
                state: '',
                postalCode: ''
            },
            management: {
                name: '',
                mobileNo: '',
                email: ''
            },
            orderRepair: {
                name: '',
                mobileNo: '',
                email: ''
            },
            logistics: {
                name: '',
                mobileNo: '',
                email: ''
            },
            accountManager: {
                name: '',
                mobileNo: '',
                email: ''
            },
            accountDetails: {
                companyPanNo: '',
                gstNo: '',
                nameOnInvoice: '',
                wefDtl: '',
                bankName: '',
                accountNo: '',
                branch: '',
                ifscCode: ''
            },
            curentOprStatus: {
                noOfRetails: '',
                majorLocation: ''
            },
            fastMovingCategory: {
                bangles: '',
                bracelets: '',
                earrings: '',
                fingerRings: '',
                necklace: '',
                nosePins: '',
                pendants: ''
            },
            typeOfRegis: 'Reseller',
            selectedOption: '',
            otherTypeData: ''
        });
    }

    showIsCompanyDtl = () => {
        this.setState({ isCompany: !this.state.isCompany });
        this.handleCheckboxChange("Other");
    }

    acceptTermCondition = () => {
        this.setState({ termConditionAccept: !this.state.termConditionAccept });
    }

    fetchData = async (userIdControl) => {
        try {
            if (!userIdControl) userIdControl = '1';
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/cart/find-by-user-id/${userIdControl}`);
            const data = await response.json();
            let totalPriceDtl = 0;
            data.forEach(element => {
                element['totalMultiplyPrice'] = Number(element.product_price) * Number(element.quantity);
                totalPriceDtl = totalPriceDtl + element.totalMultiplyPrice;
            });
            this.setState({ data: data, totalPrice: totalPriceDtl.toFixed(2) });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    async cartItemDelete(cartId) {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/cart/delete/${cartId}/${this.state.userId}`, {
            method: 'POST'
        });
        const dataList = await response.json();
    }

    addManufacturer = async (event) => {
        event.preventDefault();
        // let ownerDetails = [{
        //     owner1: [this.state.ownerDetail1]
        // }]
        const isLoggedIn = JSON.parse(localStorage.getItem('userData'));
        const formData = new FormData();
        formData.append("adUserType", this.state.typeOfRegis);
        formData.append("compName", this.state.companyName);
        formData.append("compType", this.state.selectedOption == 'Other'? this.state.otherTypeData : this.state.selectedOption);
        formData.append("compAdd1", this.state.stAddress1);
        formData.append("compAdd2", this.state.stAddress2);
        formData.append("compCity", this.state.city);
        formData.append("compState", this.state.state);
        formData.append("compPostal", this.state.pincode);
        // formData.append("address", JSON.stringify([{ "streetAdd": this.state.stAddress1, "streetAddLinetwo": this.state.stAddress2, "city": this.state.city, "state": this.state.state, "postalCode": this.state.pincode }]));
        formData.append("adEmail", this.state.email);
        formData.append("adPhone", this.state.phoneNumber);
        formData.append("ownerName", this.state.ownerDetail1.name);
        formData.append("ownerMobile", this.state.ownerDetail1.mobileNo);
        formData.append("ownerEmail", this.state.ownerDetail1.email);
        formData.append("ownerDOB", this.state.ownerDetail1.birthDate);
        formData.append("ownerAnniDate", this.state.ownerDetail1.anniversaryDate);
        formData.append("srName", this.state.showRoomDtl.name);
        formData.append("srArea", this.state.showRoomDtl.areaName);
        formData.append("srEstDate", this.state.showRoomDtl.dateOfEstd);
        formData.append("srAdd1", this.state.showRoomAddress.streetAddress);
        formData.append("srAdd2", this.state.showRoomAddress.streetAddressL2);
        formData.append("srCity", this.state.showRoomAddress.city);
        formData.append("srState", this.state.showRoomAddress.state);
        formData.append("srPin", this.state.showRoomAddress.postalCode);
        formData.append("managName", this.state.management.name);
        formData.append("managPhone", this.state.management.mobileNo);
        formData.append("managEmail", this.state.management.email);
        formData.append("serviceName", this.state.orderRepair.name);
        formData.append("servicePhone", this.state.orderRepair.mobileNo);
        formData.append("serviceEmail", this.state.orderRepair.email);
        formData.append("logisticsName", this.state.logistics.name);
        formData.append("logisticsPhone", this.state.logistics.mobileNo);
        formData.append("logisticsEmail", this.state.logistics.email);
        formData.append("accName", this.state.accountManager.name);
        formData.append("accPhone", this.state.accountManager.mobileNo);
        formData.append("accEmail", this.state.accountManager.email);
        formData.append("compPan", this.state.accountDetails.companyPanNo);
        formData.append("compGST", this.state.accountDetails.gstNo);
        formData.append("compInvoiceName", this.state.accountDetails.nameOnInvoice);
        formData.append("compWef", this.state.accountDetails.wefDtl);
        formData.append("compBankName", this.state.accountDetails.bankName);
        formData.append("compAccount", this.state.accountDetails.accountNo);
        formData.append("compBranch", this.state.accountDetails.branch);
        formData.append("compIfsc", this.state.accountDetails.ifscCode);
        formData.append("compRetail", this.state.curentOprStatus.noOfRetails);
        formData.append("compLocation", this.state.curentOprStatus.majorLocation);
        formData.append("compBA", this.state.fastMovingCategory.bangles);
        formData.append("compBR", this.state.fastMovingCategory.bracelets);
        formData.append("compER", this.state.fastMovingCategory.earrings);
        formData.append("compFR", this.state.fastMovingCategory.fingerRings);
        formData.append("compNE", this.state.fastMovingCategory.necklace);
        formData.append("compNO", this.state.fastMovingCategory.nosePins);
        formData.append("compPE", this.state.fastMovingCategory.pendants);

        // formData.append("countryCode", this.state.countryCode); missing
        // formData.append("ownerDetails", JSON.stringify(ownerDetails));
        // formData.append("showRoomDetails", JSON.stringify([this.state.showRoomDtl]));
        // formData.append("showRoomAddress", JSON.stringify([this.state.showRoomAddress]));
        // formData.append("contactPersonDetails", JSON.stringify([{ "management": this.state.management, "orderRepair": this.state.orderRepair, "logistics": this.state.logistics, "accountManager": this.state.accountManager }]));
        // formData.append("accountDetails", JSON.stringify([this.state.accountDetails]));
        // formData.append("currentOperatingSts", JSON.stringify([this.state.curentOprStatus]));
        // formData.append("fastMovingCategory", JSON.stringify([this.state.fastMovingCategory]));

        // API need to replace here
        const response = await fetch(process.env.REACT_APP_API_BASE_URL +'/api/manufacturer/register', {
            method: 'POST',
            body: formData,
        })
        if (response.ok) {
            this.setState({ successMessage: "Request Submitted. We will get back to you soon." });
            this.emptyValue();
        }
    }

    onRadioChange = async (e, type) => {
        this.setState({ typeOfRegis: type });
    }

    handleCheckboxChange = (name) => {
        this.setState({ selectedOption: name });
      };

    handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.target; // Get the form element

        // Create a new FormData object
        const formData = new FormData(form);
        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +'/api/customer-address/customerdetail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });
            if (response.ok) {
                console.log('Data inserted successfully');
                // Optionally, you can reset the form after successful submission

            } else {
                console.error('Failed to insert data');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    render() {
        if (this.state.ordersuccessPage) {
            return <Redirect to="/thank-you" />;
        }
        return (
            <section className="checkout pt-120 pb-120">
                <div className="container">
                    <form onSubmit={this.addManufacturer}>
                        <div className='justify-content-center'>
                            <div className="col-12">
                                <div className="checkout-form">
                                    <h2 className='mb-3'>KYC Details</h2>
                                    <h5 className='mb-3'>Manufacture & Retailers Registration</h5>
                                    <div className="row">
                                        <div class="check input-group">
                                            <div class="checkout-term align-items-center">
                                                <input class="check-box" type="radio" name="flexRadioDefault" id="flexRadioDefault1" onClick={(e) => this.onRadioChange(e.target.value, 'Manufacture')} />
                                                <label class="check-label input-group-two" for="flexRadioDefault1">Manufacture</label>
                                            </div>
                                            <div class="checkout-term ml-5">
                                                <input class="check-box" type="radio" name="flexRadioDefault" id="flexRadioDefault2" onClick={(e) => this.onRadioChange(e.target.value, 'Reseller')} />
                                                <label class="check-label input-group-two" for="flexRadioDefault2">Reseller</label>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 input-group input-group-two mb-20">
                                            <label>Company Name
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input type="text" placeholder="Company Name" name="customer_name" value={this.state.companyName} onChange={(e) => this.setState({ companyName: e.target.value })} required />
                                        </div>
                                        <div className='col-12 input-group input-group-two mb'>
                                            <label>Type</label>
                                            <span className="text-danger">*</span>
                                        </div>
                                        <div class="check input-group">
                                            <div className='checkout-term col-6'>
                                                <input class="check-box" type="checkbox" id="check1" name="option1" checked={this.state.selectedOption === "Individual"} onChange={() => this.handleCheckboxChange("Individual")}/>
                                                <label class="check-label input-group-two">Individual</label>
                                            </div>
                                            <div className='checkout-term col-6'>
                                                <input class="check-box" type="checkbox" id="check1" name="option2" checked={this.state.selectedOption === "Proprietor"} onChange={() => this.handleCheckboxChange("Proprietor")}/>
                                                <label class="check-label input-group-two">Proprietor</label>
                                            </div>
                                            <div className='checkout-term col-6'>
                                                <input class="check-box" type="checkbox" id="check1" name="option3" checked={this.state.selectedOption === "Partnership"} onChange={() => this.handleCheckboxChange("Partnership")}/>
                                                <label class="check-label input-group-two">Partnership</label>
                                            </div>
                                            <div className='checkout-term col-6'>
                                                <input class="check-box" type="checkbox" id="check1" name="option4" checked={this.state.selectedOption === "Pvt Ltd."} onChange={() => this.handleCheckboxChange("Pvt Ltd.")}/>
                                                <label class="check-label input-group-two">Pvt Ltd.</label>
                                            </div>
                                            <div className='checkout-term col-6'>
                                                <input class="check-box" type="checkbox" id="check1" name="option5" checked={this.state.selectedOption === "Public Ltd."} onChange={() => this.handleCheckboxChange("Public Ltd.")}/>
                                                <label class="check-label input-group-two">Public Ltd.</label>
                                            </div>
                                            <div className='checkout-term col-6'>
                                                <input class="check-box" type="checkbox" id="check1" name="option6" checked={this.state.selectedOption === "Other"} onChange={this.showIsCompanyDtl} />
                                                <label class="check-label input-group-two">Other</label>
                                            </div>
                                        </div>
                                        {this.state.isCompany &&
                                            <div className="col-12 input-group input-group-two mb-20">
                                                <input type="text" placeholder="Please type another option here" name="customer_option" onChange={(e) => this.setState({ otherTypeData: e.target.value })}/>
                                            </div>
                                        }
                                        <div className="col-12 input-group input-group-two mb-20">
                                            <label>Address
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input type="text" placeholder="Street Address" name="customer_street" value={this.state.stAddress1} onChange={(e) => this.setState({ stAddress1: e.target.value })} required />
                                        </div>
                                        <div className="col-12 input-group input-group-two mb-20">
                                            <input type="text" placeholder="Street Address Line 2" name="customer_street" value={this.state.stAddress2} onChange={(e) => this.setState({ stAddress2: e.target.value })} required />
                                        </div>
                                        <div className="col-6 input-group input-group-two mb-20">
                                            <input type="text" placeholder="City" name="customer_city" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} required />
                                        </div>
                                        <div className="col-6 input-group input-group-two mb-20">
                                            <input type="text" placeholder="State / Province" name="customer_state" value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })} required />
                                        </div>
                                        <div className="col-12 input-group input-group-two mb-20">
                                            <input type="text" placeholder="Postal / Zip Code" name="customer_zipcode" value={this.state.pincode} onChange={(e) => this.setState({ pincode: e.target.value })} required />
                                        </div>
                                        <div className="col-6 input-group input-group-two mb-20">
                                            <label>Email
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input type="mail" placeholder="example@example.com" name="customer_mail" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} required />
                                        </div>
                                        <div className="col-xl-6 input-group input-group-two mb-20">
                                            <label>Phone Number
                                                <span className="text-danger">*</span>
                                            </label>
                                            <div className='d-flex'>
                                                <select class="country-code form-control" name="rs_countrycode" id="" onChange={(e) => this.setState({ countryCode: e.target.value })} required>
                                                    <option value="93">Afghanistan (+93)</option>
                                                    <option value="355">Albania (+355)</option>
                                                    <option value="213">Algeria (+213)</option>
                                                    <option value="1684">American Samoa (+1684)</option>
                                                    <option value="376">Andorra (+376)</option>
                                                    <option value="244">Angola (+244)</option>
                                                    <option value="1264">Anguilla (+1264)</option>
                                                    <option value="672">Antarctica (+672)</option>
                                                    <option value="1268">Antigua and Barbuda (+1268)</option>
                                                    <option value="54">Argentina (+54)</option>
                                                    <option value="374">Armenia (+374)</option>
                                                    <option value="297">Aruba (+297)</option>
                                                    <option value="61">Australia (+61)</option>
                                                    <option value="43">Austria (+43)</option>
                                                    <option value="994">Azerbaijan (+994)</option>
                                                    <option value="1242">Bahamas (+1242)</option>
                                                    <option value="973">Bahrain (+973)</option>
                                                    <option value="880">Bangladesh (+880)</option>
                                                    <option value="1246">Barbados (+1246)</option>
                                                    <option value="375">Belarus (+375)</option>
                                                    <option value="32">Belgium (+32)</option>
                                                    <option value="501">Belize (+501)</option>
                                                    <option value="229">Benin (+229)</option>
                                                    <option value="1441">Bermuda (+1441)</option>
                                                    <option value="975">Bhutan (+975)</option>
                                                    <option value="591">Bolivia (+591)</option>
                                                    <option value="387">Bosnia and Herzegovina (+387)</option>
                                                    <option value="267">Botswana (+267)</option>
                                                    <option value="55">Brazil (+55)</option>
                                                    <option value="246">British Indian Ocean Territory (+246)</option>
                                                    <option value="1284">British Virgin Islands (+1284)</option>
                                                    <option value="673">Brunei (+673)</option>
                                                    <option value="359">Bulgaria (+359)</option>
                                                    <option value="226">Burkina Faso (+226)</option>
                                                    <option value="257">Burundi (+257)</option>
                                                    <option value="855">Cambodia (+855)</option>
                                                    <option value="237">Cameroon (+237)</option>
                                                    <option value="1">Canada (+1)</option>
                                                    <option value="238">Cape Verde (+238)</option>
                                                    <option value="1345">Cayman Islands (+1345)</option>
                                                    <option value="236">Central African Republic (+236)</option>
                                                    <option value="235">Chad (+235)</option>
                                                    <option value="56">Chile (+56)</option>
                                                    <option value="86">China (+86)</option>
                                                    <option value="61">Christmas Island (+61)</option>
                                                    <option value="61">Cocos Islands (+61)</option>
                                                    <option value="57">Colombia (+57)</option>
                                                    <option value="269">Comoros (+269)</option>
                                                    <option value="682">Cook Islands (+682)</option>
                                                    <option value="506">Costa Rica (+506)</option>
                                                    <option value="385">Croatia (+385)</option>
                                                    <option value="53">Cuba (+53)</option>
                                                    <option value="599">Curacao (+599)</option>
                                                    <option value="357">Cyprus (+357)</option>
                                                    <option value="420">Czech Republic (+420)</option>
                                                    <option value="243">Democratic Republic of the Congo (+243)</option>
                                                    <option value="45">Denmark (+45)</option>
                                                    <option value="253">Djibouti (+253)</option>
                                                    <option value="1767">Dominica (+1767)</option>
                                                    <option value="1809">Dominican Republic (+1809)</option>
                                                    <option value="670">East Timor (+670)</option>
                                                    <option value="593">Ecuador (+593)</option>
                                                    <option value="20">Egypt (+20)</option>
                                                    <option value="503">El Salvador (+503)</option>
                                                    <option value="240">Equatorial Guinea (+240)</option>
                                                    <option value="291">Eritrea (+291)</option>
                                                    <option value="372">Estonia (+372)</option>
                                                    <option value="251">Ethiopia (+251)</option>
                                                    <option value="500">Falkland Islands (+500)</option>
                                                    <option value="298">Faroe Islands (+298)</option>
                                                    <option value="679">Fiji (+679)</option>
                                                    <option value="358">Finland (+358)</option>
                                                    <option value="33">France (+33)</option>
                                                    <option value="689">French Polynesia (+689)</option>
                                                    <option value="241">Gabon (+241)</option>
                                                    <option value="220">Gambia (+220)</option>
                                                    <option value="995">Georgia (+995)</option>
                                                    <option value="49">Germany (+49)</option>
                                                    <option value="233">Ghana (+233)</option>
                                                    <option value="350">Gibraltar (+350)</option>
                                                    <option value="30">Greece (+30)</option>
                                                    <option value="299">Greenland (+299)</option>
                                                    <option value="1473">Grenada (+1473)</option>
                                                    <option value="1671">Guam (+1671)</option>
                                                    <option value="502">Guatemala (+502)</option>
                                                    <option value="44-1481">Guernsey (+44-1481)</option>
                                                    <option value="224">Guinea (+224)</option>
                                                    <option value="245">Guinea-Bissau (+245)</option>
                                                    <option value="592">Guyana (+592)</option>
                                                    <option value="509">Haiti (+509)</option>
                                                    <option value="504">Honduras (+504)</option>
                                                    <option value="852">Hong Kong (+852)</option>
                                                    <option value="36">Hungary (+36)</option>
                                                    <option value="354">Iceland (+354)</option>
                                                    <option value="91" selected>India (+91)</option>
                                                    <option value="62">Indonesia (+62)</option>
                                                    <option value="98">Iran (+98)</option>
                                                    <option value="964">Iraq (+964)</option>
                                                    <option value="353">Ireland (+353)</option>
                                                    <option value="44-1624">Isle of Man (+44-1624)</option>
                                                    <option value="972">Israel (+972)</option>
                                                    <option value="39">Italy (+39)</option>
                                                    <option value="225">Ivory Coast (+225)</option>
                                                    <option value="1876">Jamaica (+1876)</option>
                                                    <option value="81">Japan (+81)</option>
                                                    <option value="44-1534">Jersey (+44-1534)</option>
                                                    <option value="962">Jordan (+962)</option>
                                                    <option value="7">Kazakhstan (+7)</option>
                                                    <option value="254">Kenya (+254)</option>
                                                    <option value="686">Kiribati (+686)</option>
                                                    <option value="383">Kosovo (+383)</option>
                                                    <option value="965">Kuwait (+965)</option>
                                                    <option value="996">Kyrgyzstan (+996)</option>
                                                    <option value="856">Laos (+856)</option>
                                                    <option value="371">Latvia (+371)</option>
                                                    <option value="961">Lebanon (+961)</option>
                                                    <option value="266">Lesotho (+266)</option>
                                                    <option value="231">Liberia (+231)</option>
                                                    <option value="218">Libya (+218)</option>
                                                    <option value="423">Liechtenstein (+423)</option>
                                                    <option value="370">Lithuania (+370)</option>
                                                    <option value="352">Luxembourg (+352)</option>
                                                    <option value="853">Macau (+853)</option>
                                                    <option value="389">Macedonia (+389)</option>
                                                    <option value="261">Madagascar (+261)</option>
                                                    <option value="265">Malawi (+265)</option>
                                                    <option value="60">Malaysia (+60)</option>
                                                    <option value="960">Maldives (+960)</option>
                                                    <option value="223">Mali (+223)</option>
                                                    <option value="356">Malta (+356)</option>
                                                    <option value="692">Marshall Islands (+692)</option>
                                                    <option value="222">Mauritania (+222)</option>
                                                    <option value="230">Mauritius (+230)</option>
                                                    <option value="262">Mayotte (+262)</option>
                                                    <option value="52">Mexico (+52)</option>
                                                    <option value="691">Micronesia (+691)</option>
                                                    <option value="373">Moldova (+373)</option>
                                                    <option value="377">Monaco (+377)</option>
                                                    <option value="976">Mongolia (+976)</option>
                                                    <option value="382">Montenegro (+382)</option>
                                                    <option value="1664">Montserrat (+1664)</option>
                                                    <option value="212">Morocco (+212)</option>
                                                    <option value="258">Mozambique (+258)</option>
                                                    <option value="95">Myanmar (+95)</option>
                                                    <option value="264">Namibia (+264)</option>
                                                    <option value="674">Nauru (+674)</option>
                                                    <option value="977">Nepal (+977)</option>
                                                    <option value="31">Netherlands (+31)</option>
                                                    <option value="599">Netherlands Antilles (+599)</option>
                                                    <option value="687">New Caledonia (+687)</option>
                                                    <option value="64">New Zealand (+64)</option>
                                                    <option value="505">Nicaragua (+505)</option>
                                                    <option value="227">Niger (+227)</option>
                                                    <option value="234">Nigeria (+234)</option>
                                                    <option value="683">Niue (+683)</option>
                                                    <option value="850">North Korea (+850)</option>
                                                    <option value="1670">Northern Mariana Islands (+1670)</option>
                                                    <option value="47">Norway (+47)</option>
                                                    <option value="968">Oman (+968)</option>
                                                    <option value="92">Pakistan (+92)</option>
                                                    <option value="680">Palau (+680)</option>
                                                    <option value="970">Palestine (+970)</option>
                                                    <option value="507">Panama (+507)</option>
                                                    <option value="675">Papua New Guinea (+675)</option>
                                                    <option value="595">Paraguay (+595)</option>
                                                    <option value="51">Peru (+51)</option>
                                                    <option value="63">Philippines (+63)</option>
                                                    <option value="64">Pitcairn (+64)</option>
                                                    <option value="48">Poland (+48)</option>
                                                    <option value="351">Portugal (+351)</option>
                                                    <option value="1787">Puerto Rico (+1787)</option>
                                                    <option value="974">Qatar (+974)</option>
                                                    <option value="242">Republic of the Congo (+242)</option>
                                                    <option value="262">Reunion (+262)</option>
                                                    <option value="40">Romania (+40)</option>
                                                    <option value="7">Russia (+7)</option>
                                                    <option value="250">Rwanda (+250)</option>
                                                    <option value="590">Saint Barthelemy (+590)</option>
                                                    <option value="290">Saint Helena (+290)</option>
                                                    <option value="1869">Saint Kitts and Nevis (+1869)</option>
                                                    <option value="1758">Saint Lucia (+1758)</option>
                                                    <option value="590">Saint Martin (+590)</option>
                                                    <option value="508">Saint Pierre and Miquelon (+508)</option>
                                                    <option value="1784">Saint Vincent and the Grenadines (+1784)</option>
                                                    <option value="685">Samoa (+685)</option>
                                                    <option value="378">San Marino (+378)</option>
                                                    <option value="239">Sao Tome and Principe (+239)</option>
                                                    <option value="966">Saudi Arabia (+966)</option>
                                                    <option value="221">Senegal (+221)</option>
                                                    <option value="381">Serbia (+381)</option>
                                                    <option value="248">Seychelles (+248)</option>
                                                    <option value="232">Sierra Leone (+232)</option>
                                                    <option value="65">Singapore (+65)</option>
                                                    <option value="1721">Sint Maarten (+1721)</option>
                                                    <option value="421">Slovakia (+421)</option>
                                                    <option value="386">Slovenia (+386)</option>
                                                    <option value="677">Solomon Islands (+677)</option>
                                                    <option value="252">Somalia (+252)</option>
                                                    <option value="27">South Africa (+27)</option>
                                                    <option value="82">South Korea (+82)</option>
                                                    <option value="211">South Sudan (+211)</option>
                                                    <option value="34">Spain (+34)</option>
                                                    <option value="94">Sri Lanka (+94)</option>
                                                    <option value="249">Sudan (+249)</option>
                                                    <option value="597">Suriname (+597)</option>
                                                    <option value="47">Svalbard and Jan Mayen (+47)</option>
                                                    <option value="268">Swaziland (+268)</option>
                                                    <option value="46">Sweden (+46)</option>
                                                    <option value="41">Switzerland (+41)</option>
                                                    <option value="963">Syria (+963)</option>
                                                    <option value="886">Taiwan (+886)</option>
                                                    <option value="992">Tajikistan (+992)</option>
                                                    <option value="255">Tanzania (+255)</option>
                                                    <option value="66">Thailand (+66)</option>
                                                    <option value="228">Togo (+228)</option>
                                                    <option value="690">Tokelau (+690)</option>
                                                    <option value="676">Tonga (+676)</option>
                                                    <option value="1868">Trinidad and Tobago (+1868)</option>
                                                    <option value="216">Tunisia (+216)</option>
                                                    <option value="90">Turkey (+90)</option>
                                                    <option value="993">Turkmenistan (+993)</option>
                                                    <option value="1649">Turks and Caicos Islands (+1649)</option>
                                                    <option value="688">Tuvalu (+688)</option>
                                                    <option value="1340">U.S. Virgin Islands (+1340)</option>
                                                    <option value="256">Uganda (+256)</option>
                                                    <option value="380">Ukraine (+380)</option>
                                                    <option value="971">United Arab Emirates (+971)</option>
                                                    <option value="44">United Kingdom (+44)</option>
                                                    <option value="1">United States (+1)</option>
                                                    <option value="598">Uruguay (+598)</option>
                                                    <option value="998">Uzbekistan (+998)</option>
                                                    <option value="678">Vanuatu (+678)</option>
                                                    <option value="379">Vatican (+379)</option>
                                                    <option value="58">Venezuela (+58)</option>
                                                    <option value="84">Vietnam (+84)</option>
                                                    <option value="681">Wallis and Futuna (+681)</option>
                                                    <option value="212">Western Sahara (+212)</option>
                                                    <option value="967">Yemen (+967)</option>
                                                    <option value="260">Zambia (+260)</option>
                                                    <option value="263">Zimbabwe (+263)</option>
                                                </select>
                                                <input type="text" placeholder="Phone Number" name="number" value={this.state.phoneNumber} onChange={(e) => this.setState({ phoneNumber: e.target.value })} required />
                                            </div>
                                        </div>
                                        <div className='col-12 input-group input-group-two mb'>
                                            <label>Owner Details</label>
                                            <span className="text-danger">*</span>
                                        </div>
                                        <div className='col-12'>
                                            <div className="checkout-table">
                                                <table>
                                                    {/* <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Owner 1</th>
                                                            <th>Owner 2</th>
                                                            <th>Owner 3</th>
                                                        </tr>
                                                    </thead> */}
                                                    <tbody className='table_input'>
                                                        <tr>
                                                            <td>
                                                                Name
                                                            </td>

                                                            <td>
                                                                <input type='text' placeholder='Enter Owner Name' value={this.state.ownerDetail1.name} onChange={(e) => this.setState({ ownerDetail1: { ...this.state.ownerDetail1, ["name"]: e.target.value } })} />
                                                            </td>

                                                            {/* <td>
                                                                <input type='text' onChange={(e) => this.setState({ ownerDetail2: { ...this.state.ownerDetail2, ["name"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='text' onChange={(e) => this.setState({ ownerDetail3: { ...this.state.ownerDetail3, ["name"]: e.target.value } })} />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Mobile No.
                                                            </td>

                                                            <td>
                                                                <input type='tel' placeholder='Enter Owner Mobile Number' value={this.state.ownerDetail1.mobileNo} onChange={(e) => this.setState({ ownerDetail1: { ...this.state.ownerDetail1, ["mobileNo"]: e.target.value } })} />
                                                            </td>

                                                            {/* <td>
                                                                <input type='text' onChange={(e) => this.setState({ ownerDetail2: { ...this.state.ownerDetail2, ["mobileNo"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='text' onChange={(e) => this.setState({ ownerDetail3: { ...this.state.ownerDetail3, ["mobileNo"]: e.target.value } })} />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Email
                                                            </td>

                                                            <td>
                                                                <input type='mail' placeholder='Enter Owner E-Mail Id' value={this.state.ownerDetail1.email} onChange={(e) => this.setState({ ownerDetail1: { ...this.state.ownerDetail1, ["email"]: e.target.value } })} />
                                                            </td>

                                                            {/* <td>
                                                                <input type='text' onChange={(e) => this.setState({ ownerDetail2: { ...this.state.ownerDetail2, ["email"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='text' onChange={(e) => this.setState({ ownerDetail3: { ...this.state.ownerDetail3, ["email"]: e.target.value } })} />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Birth Date
                                                            </td>

                                                            <td>
                                                                <input type='date' placeholder='Enter Owner Date of Birth' value={this.state.ownerDetail1.birthDate} onChange={(e) => this.setState({ ownerDetail1: { ...this.state.ownerDetail1, ["birthDate"]: e.target.value } })} />
                                                            </td>

                                                            {/* <td>
                                                                <input type='text' onChange={(e) => this.setState({ ownerDetail2: { ...this.state.ownerDetail2, ["birthDate"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='text' onChange={(e) => this.setState({ ownerDetail3: { ...this.state.ownerDetail3, ["birthDate"]: e.target.value } })} />
                                                            </td> */}
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Anniversary Date
                                                            </td>

                                                            <td>
                                                                <input type='date' value={this.state.ownerDetail1.anniversaryDate} onChange={(e) => this.setState({ ownerDetail1: { ...this.state.ownerDetail1, ["anniversaryDate"]: e.target.value } })} />
                                                            </td>

                                                            {/* <td>
                                                                <input type='text' onChange={(e) => this.setState({ ownerDetail2: { ...this.state.ownerDetail2, ["anniversaryDate"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='text' onChange={(e) => this.setState({ ownerDetail3: { ...this.state.ownerDetail3, ["anniversaryDate"]: e.target.value } })} />
                                                            </td> */}
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className='col-12 input-group input-group-two mb'>
                                            <label>Show Room Details / Factory</label>
                                            <span className="text-danger">*</span>
                                        </div>
                                        <div className='col-12'>
                                            <div className="checkout-table">
                                                <table>
                                                    <tbody className='table_input'>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Name
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter the name of Factory / Showroom' value={this.state.showRoomDtl.name} onChange={(e) => this.setState({ showRoomDtl: { ...this.state.showRoomDtl, ["name"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Area Name
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Area' value={this.state.showRoomDtl.areaName} onChange={(e) => this.setState({ showRoomDtl: { ...this.state.showRoomDtl, ["areaName"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Date of Estd.
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='date' placeholder='Enter the Date Established' value={this.state.showRoomDtl.dateOfEstd} onChange={(e) => this.setState({ showRoomDtl: { ...this.state.showRoomDtl, ["dateOfEstd"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className="col-12 input-group input-group-two mb-20">
                                            <label>Address - Show Room / Factory
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input type="text" placeholder="Street Address" name="customer_street" value={this.state.showRoomAddress.streetAddress} onChange={(e) => this.setState({ showRoomAddress: { ...this.state.showRoomAddress, ["streetAddress"]: e.target.value } })} required />
                                        </div>
                                        <div className="col-12 input-group input-group-two mb-20">
                                            <input type="text" placeholder="Street Address Line 2" name="customer_street" value={this.state.showRoomAddress.streetAddressL2} onChange={(e) => this.setState({ showRoomAddress: { ...this.state.showRoomAddress, ["streetAddressL2"]: e.target.value } })} required />
                                        </div>
                                        <div className="col-6 input-group input-group-two mb-20">
                                            <input type="text" placeholder="City" name="customer_city" value={this.state.showRoomAddress.city} onChange={(e) => this.setState({ showRoomAddress: { ...this.state.showRoomAddress, ["city"]: e.target.value } })} required />
                                        </div>
                                        <div className="col-6 input-group input-group-two mb-20">
                                            <input type="text" placeholder="State / Province" name="customer_state" value={this.state.showRoomAddress.state} onChange={(e) => this.setState({ showRoomAddress: { ...this.state.showRoomAddress, ["state"]: e.target.value } })} required />
                                        </div>
                                        <div className="col-12 input-group input-group-two mb-20">
                                            <input type="text" placeholder="Postal / Zip Code" name="customer_zipcode" value={this.state.showRoomAddress.postalCode} onChange={(e) => this.setState({ showRoomAddress: { ...this.state.showRoomAddress, ["postalCode"]: e.target.value } })} required />
                                        </div>

                                        <div className='col-12 input-group input-group-two mb'>
                                            <label>Contact Person Details</label>
                                            <span className="text-danger">*</span>
                                        </div>
                                        <div className='col-12'>
                                            <div className="checkout-table">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th></th>
                                                            <th>Name</th>
                                                            <th>Mobile Number</th>
                                                            <th>Email</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className='table_input'>
                                                        <tr>
                                                            <td>
                                                                Management
                                                            </td>

                                                            <td>
                                                                <input type='text' placeholder='Enter Name' value={this.state.management.name} onChange={(e) => this.setState({ management: { ...this.state.management, ["name"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='tel' placeholder='Enter Mobile Number' value={this.state.management.mobileNo} onChange={(e) => this.setState({ management: { ...this.state.management, ["mobileNo"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='mail' placeholder='Enter Mail Id' value={this.state.management.email} onChange={(e) => this.setState({ management: { ...this.state.management, ["email"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Order / Repair
                                                            </td>

                                                            <td>
                                                                <input type='text' placeholder='Enter Name' value={this.state.orderRepair.name} onChange={(e) => this.setState({ orderRepair: { ...this.state.orderRepair, ["name"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='tel' placeholder='Enter Mobile Number' value={this.state.orderRepair.mobileNo} onChange={(e) => this.setState({ orderRepair: { ...this.state.orderRepair, ["mobileNo"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='mail' placeholder='Enter Mail Id' value={this.state.orderRepair.email} onChange={(e) => this.setState({ orderRepair: { ...this.state.orderRepair, ["email"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Logistics
                                                            </td>

                                                            <td>
                                                                <input type='text' placeholder='Enter Name' value={this.state.logistics.name} onChange={(e) => this.setState({ logistics: { ...this.state.logistics, ["name"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='tel' placeholder='Enter Mobile Number' value={this.state.logistics.mobileNo} onChange={(e) => this.setState({ logistics: { ...this.state.logistics, ["mobileNo"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='mail' placeholder='Enter Mail Id' value={this.state.logistics.email} onChange={(e) => this.setState({ logistics: { ...this.state.logistics, ["email"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Accounts Manager
                                                            </td>

                                                            <td>
                                                                <input type='text' placeholder='Enter Name' value={this.state.accountManager.name} onChange={(e) => this.setState({ accountManager: { ...this.state.accountManager, ["name"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='tel' placeholder='Enter Mobile Number' value={this.state.accountManager.mobileNo} onChange={(e) => this.setState({ accountManager: { ...this.state.accountManager, ["mobileNo"]: e.target.value } })} />
                                                            </td>

                                                            <td>
                                                                <input type='mail' placeholder='Enter Mail Id' value={this.state.accountManager.email} onChange={(e) => this.setState({ accountManager: { ...this.state.accountManager, ["email"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className='col-12 input-group input-group-two mb'>
                                            <label>Accounts Details</label>
                                            <span className="text-danger">*</span>
                                        </div>
                                        <div className='col-12'>
                                            <div className="checkout-table">
                                                <table>
                                                    <tbody className='table_input'>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Company Pan No.
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Company PAN Number' value={this.state.accountDetails.companyPanNo} onChange={(e) => this.setState({ accountDetails: { ...this.state.accountDetails, ["companyPanNo"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                GST No.
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter GST Number' value={this.state.accountDetails.gstNo} onChange={(e) => this.setState({ accountDetails: { ...this.state.accountDetails, ["gstNo"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Name on Invoice
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Name on Invoice' value={this.state.accountDetails.nameOnInvoice} onChange={(e) => this.setState({ accountDetails: { ...this.state.accountDetails, ["nameOnInvoice"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                W.e.f
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter W.e.f (With Effect From) Date' value={this.state.accountDetails.wefDtl} onChange={(e) => this.setState({ accountDetails: { ...this.state.accountDetails, ["wefDtl"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Bank Name
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Bank Name' value={this.state.accountDetails.bankName} onChange={(e) => this.setState({ accountDetails: { ...this.state.accountDetails, ["bankName"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Account No.
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Account Number' value={this.state.accountDetails.accountNo} onChange={(e) => this.setState({ accountDetails: { ...this.state.accountDetails, ["accountNo"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Branch
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Branch Name' value={this.state.accountDetails.branch} onChange={(e) => this.setState({ accountDetails: { ...this.state.accountDetails, ["branch"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                IFSC Code
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter IFSC Code' value={this.state.accountDetails.ifscCode} onChange={(e) => this.setState({ accountDetails: { ...this.state.accountDetails, ["ifscCode"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className='col-12 input-group input-group-two mb'>
                                            <label>Current Operating status</label>
                                            <span className="text-danger">*</span>
                                        </div>
                                        <div className='col-12'>
                                            <div className="checkout-table">
                                                <table>
                                                    <tbody className='table_input'>
                                                        <tr>
                                                            <td className='col-6'>
                                                                No Of Retails
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='number' placeholder='Enter No of Retails' value={this.state.curentOprStatus.noOfRetails} onChange={(e) => this.setState({ curentOprStatus: { ...this.state.curentOprStatus, ["noOfRetails"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Major Locations
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Major Locations' value={this.state.curentOprStatus.majorLocation} onChange={(e) => this.setState({ curentOprStatus: { ...this.state.curentOprStatus, ["majorLocation"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        <div className='col-12 input-group input-group-two mb'>
                                            <label>Fast moving category %</label>
                                            <span className="text-danger">*</span>
                                        </div>
                                        <div className='col-12'>
                                            <div className="checkout-table">
                                                <table>
                                                    <tbody className='table_input'>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Bangles
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Sales % of Bangles' value={this.state.fastMovingCategory.bangles} onChange={(e) => this.setState({ fastMovingCategory: { ...this.state.fastMovingCategory, ["bangles"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Bracelets
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Sales % of Bracelets' value={this.state.fastMovingCategory.bracelets} onChange={(e) => this.setState({ fastMovingCategory: { ...this.state.fastMovingCategory, ["bracelets"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Earrings
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Sales % of Earrings' value={this.state.fastMovingCategory.earrings} onChange={(e) => this.setState({ fastMovingCategory: { ...this.state.fastMovingCategory, ["earrings"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Finger rings
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Sales % of Finger rings' value={this.state.fastMovingCategory.fingerRings} onChange={(e) => this.setState({ fastMovingCategory: { ...this.state.fastMovingCategory, ["fingerRings"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Necklace
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Sales % of Necklace' value={this.state.fastMovingCategory.necklace} onChange={(e) => this.setState({ fastMovingCategory: { ...this.state.fastMovingCategory, ["necklace"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Nose Pins
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Sales % of Nose Pins' value={this.state.fastMovingCategory.nosePins} onChange={(e) => this.setState({ fastMovingCategory: { ...this.state.fastMovingCategory, ["nosePins"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className='col-6'>
                                                                Pendants
                                                            </td>

                                                            <td className='col-6'>
                                                                <input type='text' placeholder='Enter Sales % of Pendants' value={this.state.fastMovingCategory.pendants} onChange={(e) => this.setState({ fastMovingCategory: { ...this.state.fastMovingCategory, ["pendants"]: e.target.value } })} />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sub-btn mt-3">
                                        {/* <Link to="/thank-you"> */}
                                        <button type="submit" className="main-btn btn-filled">Submit</button>
                                        {/* </Link> */}
                                    </div>

                                    {this.state.successMessage != '' && (
                                            <div className="alert alert-success margin-top" role="alert">
                                                {this.state.successMessage}
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
        );
    }
}

export default Content;