import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import registerbg from "../../../assets/img/login.jpg";

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            password: '',
            registrationSuccess: false,
            errorMessage: '',
            redirectToLogin: false,
            errorUsername: '',
            errorEmail: '',
        };
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        
        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +'/api/auth/register', {
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem('registrationSuccess', 'true');
                this.setState({ 
                    registrationSuccess: true,
                    username: '',
                    email: '',
                    password: '',
                    redirectToLogin: true 
                });
            } else {
                this.setState({ errorMessage: 'An error occurred while registering. Please try again later.' });
                this.setState({ errorUsername: data.message.username || '' }); // Set error message for username
                this.setState({ errorEmail: data.message.email || '' });       // Set error message for email
            }
        } catch (error) {
            console.error('Error registering user:', error);
            this.setState({ errorMessage: 'An error occurred while registering. Please try again later.' });
        }
    };

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { username, email, password, registrationSuccess, errorMessage, redirectToLogin, errorUsername, errorEmail } = this.state;

        if (redirectToLogin) {
            return <Redirect to="/login" />;
        }

        return (
            <section className="login-sec pt-120 pb-120">
                <div className="container">
                    <div className="account-wrapper">
                        <div className="row no-gutters">
                            <div className="col-lg-6">
                                <div className="login-content" style={{ backgroundImage: "url(" + registerbg + ")" }}>
                                    <div className="description text-center">
                                        <h2>Welcome</h2>
                                        <p className="text-white">"Begin your journey to timeless elegance."</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="login-form">
                                    <h2>Sign Up</h2>
                                    {registrationSuccess ? (
                                        <div className="alert alert-success" role="alert">
                                            Registration successful. Kindly <Link to="/login">login</Link>.
                                        </div>
                                    ) : errorMessage && (
                                        <div className="alert alert-danger" role="alert">
                                            {errorMessage}
                                        </div>
                                    )}
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="input-group input-group-two mb-20">
                                            <input type="text" placeholder="Username" name="username" value={username} onChange={this.handleChange} />
                                            <small className='text-danger'>{errorUsername}</small> {/* Display error message for username */}
                                        </div>
                                        <div className="input-group input-group-two mb-20">
                                            <input type="email" placeholder="Email Address" name="email" value={email} onChange={this.handleChange} />
                                            <small className='text-danger'>{errorEmail}</small> {/* Display error message for email */}
                                        </div>
                                        <div className="input-group input-group-two mb-30">
                                            <input type="password" placeholder="Password" name="password" value={password} onChange={this.handleChange} />
                                        </div>
                                        <Link to="#">Forgot Password?</Link>
                                        <button type="submit" className="main-btn btn-filled mt-20 login-btn">Signup</button>
                                        <div className="form-seperator">
                                            <span>OR</span>
                                        </div>
                                        <p>Already have an Account?
                                            <Link to="/login" className="d-inline-block">Login</Link>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Content;
