import React, { Component } from 'react';
import { Tab} from 'react-bootstrap';
import { Link } from 'react-router-dom';

import img1 from '../../../assets/img/shop/cart-1.png';
// import img2 from '../../../assets/img/shop/cart-2.png';
// import img3 from '../../../assets/img/shop/cart-3.png';

const orderlists = [
    // { img: img1, title: 'Product1', id: 'b123jhk4h', date: '12-Sep-2022', status: 'Delivered' },
    // { img: img2, title: 'Product2', id: 'b673juyk4h', date: '14-Sep-2022', status: 'In transit' },
    // { img: img3, title: 'Product3', id: 'Q123jh4h', date: '12-Sep-2022', status: 'Cancelled' },
    // { img: img1, title: 'Product4', id: 'R444lo98', date: '20-Sep-2022', status: 'Delivered' },
    { img: img1, title: 'Product1', id: 'b123jhk4h', date: '12-Sep-2022', status: 'Making' },
];
class Content extends Component {
    render() {
        return (
            <section className="account-sec pt-120 pb-120">
                <Tab.Container defaultActiveKey="profile">
                    <div className="container">
                        <div>
                            {/* <div className="content-heading mb-50">
                                                <h3>My Orders</h3>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                            </div> */}
                            <div className="order-table">
                                <table className="table cw-cart-table mb-0">
                                    <thead>
                                        <tr>
                                            {/* <th /> */}
                                            <th scope="col" className="product-name">My Order</th>
                                            <th scope="col" className="product-qty">Order ID</th>
                                            <th scope="col" className="product-price">Order Date</th>
                                            <th scope="col" className="product-status">Order Status</th>
                                            <th scope="col" className="product-details"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderlists.map((item, i) => (
                                            <tr key={i}>
                                                {/* <td className="product-remove text-center cw-align">
                                                                    <Link to="#"><i className="fas fa-times" /></Link>
                                                                </td> */}
                                                <td data-title="Product" className="has-title">
                                                    <div className="product-thumbnail">
                                                        <img src={item.img} alt="product_thumbnail" />
                                                    </div>
                                                    <Link to="/cad-design">{item.title}</Link>
                                                </td>
                                                <td className="product-price text-white cw-align has-title" data-title="Order ID">#{item.id}</td>
                                                <td className="product-price text-white cw-align has-title" data-title="Order Date">{item.date}</td>
                                                <td className="product-price text-white cw-align has-title" data-title="Order Status">{item.status}</td>
                                                <td data-title="Actions" className="has-title">
                                                    <Link to="/cad-design" className="main-btn btn-filled">View Design</Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            </section>
        );
    }
}

export default Content;