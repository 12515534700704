import React, { Component, Fragment } from 'react';
import MetaTags from "react-meta-tags";
import Header from '../layouts/Headertwo';
import Breadcrumb from '../layouts/Breadcrumbs';
import Content from '../sections/harvestdetails/Content';
import Instafeeds from '../layouts/Instafeeds';
import Footer from '../layouts/Footertwo';

class Milliharvest extends Component{
    render() {
        return(
            <Fragment>
                <MetaTags>
                    <title>Milli | Harvest</title>
                    <meta
                        name="description"
                        content="#"
                    />
                </MetaTags>
                <Header/>
                <Breadcrumb breadcrumb={{pagename:'Mili Harvest'}}/>
                <Content/>
                <Instafeeds/>
                <Footer/>
            </Fragment>
        )
    }
}
export default Milliharvest;