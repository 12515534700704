import React, { Component, Fragment } from 'react';
import Banner from './Banner';
import Collection from './Collection';
import Video from './Video';
// import Latestproducts from '../../layouts/Latestproducts';
import Jwelsale from './Jwelsale';
import Bestfeature from './Bestfeature';
// import Handpick from './Handpick';
// import Productslider from './Productslider';

class Content extends Component {
    render() {
        return (
            <Fragment>
                <Banner/>
                <Collection/>
                <Jwelsale/>
                {/* <Categorybox/> */}
                <Video/>
                {/* <Latestproducts/> */}
                <Bestfeature/>
                {/* <Handpick/> */}
                {/* <Productslider/> */}
                {/* <Blogpost/> */}
            </Fragment>
        );
    }
}

export default Content;