import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Preloader
import Preloader from './components/layouts/Preloader';
// Pages
// import Hometwo from './components/pages/Home';
import Home from './components/pages/Hometwo';
// import Homethree from './components/pages/Homethree';
// import Homefour from './components/pages/Homefour';
// import About from './components/pages/About';
import Aboutus from './components/pages/Aboutmilli';
import Account from './components/pages/Account';
import Activeorders from './components/pages/Activeorders';
import Completeorders from './components/pages/Completeorders';
import Customorders from './components/pages/Customorders';
import Cancelledorders from './components/pages/Cancelledorders';
// import Blogdetail from './components/pages/Blogdetail';
// import Bloggrid from './components/pages/Bloggrid';
// import Bloggridsidebar from './components/pages/Bloggridsidebar';
// import Bloglist from './components/pages/Bloglist';
import Caddesign from './components/pages/Caddesign';
import Cart from './components/pages/Cart';
import Checkout from './components/pages/Checkout';
// import Classification from './components/pages/Classification';
// import Comingsoon from './components/pages/Comingsoon';
// import Contact from './components/pages/Contact';
import Customdesign from './components/pages/Customdesign';
import Error from './components/pages/Error';
// import Faq from './components/pages/Faq';
import Forgotpassword from './components/pages/Forgot-password'
// import Gallery from './components/pages/Gallery';
// import Gallerytwo from './components/pages/Gallerytwo';
import Legal from './components/pages/Legal';
import Login from './components/pages/Login';
import Manufacturer from './components/pages/Manufacturer';
import Milliharvest from './components/pages/Milliharvest';
import Newmanufacturer from './components/pages/NewmManufacturer';
import Orderdetails from './components/pages/Orderdetails';
import otp from './components/pages/Otp';
import Register from './components/pages/Register';
import Resetpassword from './components/pages/Reset-password';
import Shopdetail from './components/pages/Shopdetail';
import Shopleft from './components/pages/Shopleft';
// import Shoplefttwo from './components/pages/Shoplefttwo';
// import Shoplist from './components/pages/Shoplist'
// import Shopright from './components/pages/Shopright';
// import Shoprighttwo from './components/pages/Shoprighttwo';
import Store from './components/pages/Store';
// import Team from './components/pages/Team';
import Thankyou from './components/pages/Thankyou';
// import Typography from './components/pages/Typography';
import Wishlist from './components/pages/Wishlist';
import axios from 'axios';
import { Provider } from 'react-redux';
import { store } from './store';

axios.defaults.baseURL = "/"

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Preloader />
        <Switch>
          <Route exact path="/" component={Home} />
          {/* <Route exact path="/home-two" component={Hometwo} /> */}
          {/* <Route exact path="/home-three" component={Homethree} /> */}
          {/* <Route exact path="/home-four" component={Homefour} /> */}
          {/* <Route exact path="/about" component={About} /> */}
          <Route exact path="/about-us" component={Aboutus} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/active-orders" component={Activeorders} />
          <Route exact path="/completed-orders" component={Completeorders} />
          <Route exact path="/custom-orders" component={Customorders} />
          <Route exact path="/cancelled-orders" component={Cancelledorders} />
          {/* <Route exact path="/blog-detail" component={Blogdetail} /> */}
          {/* <Route exact path="/blog-grid" component={Bloggrid} /> */}
          {/* <Route exact path="/blog-grid-sidebar" component={Bloggridsidebar} /> */}
          {/* <Route exact path="/blog-list" component={Bloglist} /> */}
          <Route exact path="/cad-design" component={Caddesign}/>
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/checkout" component={Checkout} />
          {/* <Route exact path="/classification" component={Classification} /> */}
          {/* <Route exact path="/coming-soon" component={Comingsoon} /> */}
          {/* <Route exact path="/contact" component={Contact} /> */}
          <Route exact path="/custom-design" component={Customdesign} />
          {/* <Route exact path="/faq" component={Faq} /> */}
          <Route exact path="/forgot-password" component={Forgotpassword} />
          {/* <Route exact path="/gallery" component={Gallery} /> */}
          {/* <Route exact path="/gallery-two" component={Gallerytwo} /> */}
          <Route exact path="/legal" component={Legal} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/milli-harvest" component={Milliharvest} />
          <Route exact path="/manufacturer" component={Manufacturer} />
          <Route exact path="/new-manufacturer" component={Newmanufacturer}/>
          <Route exact path="/order-details" component={Orderdetails}/>
          <Route exact path="/otp" component={otp}/>
          <Route exact path="/register" component={Register} />
          <Route exact path="/reset-password" component={Resetpassword}/>
          <Route exact path="/product-detail/:id" component={Shopdetail} />
          <Route exact path="/jewellery-by-price/:min/:max" component={Shopleft} />
          <Route exact path="/jewellery-by-price1/:min/:max" component={Shopleft} />
          <Route exact path="/jewellery-by-price2/:min/:max" component={Shopleft} />
          <Route exact path="/jewellery-by-price3/:min/:max" component={Shopleft} />
          <Route exact path="/jewellery-by-price4/:min/:max" component={Shopleft} />
          <Route exact path="/jewellery-by-price5/:min/:max" component={Shopleft} />
          <Route exact path="/jewellery-by-price6/:min/:max" component={Shopleft} />
          <Route exact path="/alljewellery" component={Shopleft} />
          <Route exact path="/alljewellery/:page" component={Shopleft} />
          <Route exact path="/alljewellery/productfilter/:filterType" component={Shopleft} />
          {/* <Route exact path="/shop-left-two" component={Shoplefttwo} /> */}
          {/* <Route exact path="/shop-list" component={Shoplist} /> */}
          {/* <Route exact path="/shop-right" component={Shopright} /> */}
          {/* <Route exact path="/shop-right-two" component={Shoprighttwo} /> */}
          <Route exact path="/store" component={Store} />
          {/* <Route exact path="/team" component={Team} /> */}
          <Route exact path="/thank-you" component={Thankyou} />
          {/* <Route exact path="/typography" component={Typography} /> */}
          <Route exact path="/wishlist" component={Wishlist} />
          <Route exact path="/error" component={Error} />
          <Route exact component={Error} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
