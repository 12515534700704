import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// import img1 from '../../../assets/img/shop/cart-1.png';
// import img2 from '../../../assets/img/shop/cart-2.png';
import Cookies from 'universal-cookie';
import { addCartList } from "../../../slices/customerSlice";
import { connect } from 'react-redux';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicks: 1,
            show: true,
            data: [],
            totalPrice: 0,
            isUserLogin: false,
            userId: 0
        };
    }
    IncrementItem = (index) => {
        // var dataList = [];
        // dataList = [...this.state.data];
        // dataList[index]['quantity'] = Number(dataList[index]['quantity']) + 1;
        // dataList[index].totalMultiplyPrice = Number(dataList[index].product_price) * Number(dataList[index].quantity);
        // this.setState({ data: dataList });
        const { data } = this.state;
        const updatedData = [...data];


        const updatedItem = { ...updatedData[index] };

        updatedItem.quantity = Number(updatedItem.quantity) + 1;
        updatedItem.totalMultiplyPrice = Number(updatedItem.product_price) * Number(updatedItem.quantity);

        updatedData[index] = updatedItem;

        this.setState({ data: updatedData });
    };

    DecreaseItem = (index) => {
        const { data } = this.state;
        const updatedData = [...data]; 

        const updatedItem = { ...updatedData[index] };

        updatedItem.quantity = Math.max(Number(updatedItem.quantity) - 1, 0); 
        updatedItem.totalMultiplyPrice = Number(updatedItem.product_price) * Number(updatedItem.quantity);

        updatedData[index] = updatedItem;

        this.setState({ data: updatedData });

    };
    handleChange(event) {
        this.setState({ clicks: event.target.value });
    }
    componentDidMount() {
        const isLoggedIn = JSON.parse(localStorage.getItem('userData'));
        if (isLoggedIn) {
            this.setState({ isUserLogin: true, userId: isLoggedIn.userId });
            this.fetchData(isLoggedIn.userId);
        } else {
            this.setState({ isUserLogin: false });
            this.getCookiesCartList();
        }
    }

    fetchData = async (userIdControl) => {
        try {
            if (!userIdControl) userIdControl = '1';
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/cart/find-by-user-id/${userIdControl}`);

            let data = await response.json();
            let totalPriceDtl = 0;
            if (data[0] == undefined) {
                data = [];
            }
            data.forEach(element => {
                element['totalMultiplyPrice'] = Number(element.product_price) * Number(element.quantity);
                totalPriceDtl = totalPriceDtl + element.totalMultiplyPrice;
            });
            this.props.addCartList(data);

            this.setState({ data: data ? data : [], totalPrice: totalPriceDtl.toFixed(2) });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    getCookiesCartList() {
        const cookies = new Cookies();
        let cartItems = cookies.get('cartItems') || [];
        let idControl = '';
        cartItems.forEach(element => {
            idControl = idControl + ',' + element.productId;
        });
        // this.setState({ cartItems });
        if (cartItems.length > 0) {
            this.getProductListById(idControl.slice(1, idControl.length), cartItems);
        } else {
            this.props.addCartList([]);
            this.setState({ data: [], totalPrice: 0 });
        }
    }

    getProductListById = async (idControl, cartItems) => {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/product/get_product_detail?cartList=${idControl}`, {
            method: 'POST',
            // body: apiData
        });
        const dataList = await response.json();
        let totalPriceDtl = 0;
        dataList.forEach(element => {
            let quantityControl = cartItems?.filter((data) => {
                return data.productId == element.product_id
            });
            element['totalMultiplyPrice'] = Number(element.product_price) * Number(quantityControl[0]?.quantity);
            element['quantity'] = quantityControl[0]?.quantity;
            totalPriceDtl = totalPriceDtl + element.totalMultiplyPrice;
        });
        this.props.addCartList(dataList);
        this.setState({ data: dataList, totalPrice: totalPriceDtl.toFixed(2) });
    }

    cartUpdate = async () => {
        let arrayControl = [];
        if (this.state.isUserLogin) {
            this.state.data.forEach(element => {
                let obj = {
                    "product_id": element.product_id,
                    "quantity": element.quantity,
                    "user_id": this.state.userId,
                    "cartId": element.cartId
                }
                arrayControl.push(obj);
            });
            console.log(JSON.stringify(arrayControl));
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/cart/updateCart`, {
                method: 'POST',
                body: JSON.stringify(arrayControl)
            }).then((res) => {
                this.fetchData(this.state.userId);
            });

        } else {
            const cookies = new Cookies();
            let cartItems = cookies.get('cartItems') || [];
            this.state.data.forEach(element => {
                let existingProductIndex = cartItems.findIndex(item => item.productId === element.product_id);
                let quatilyData = 1;
                if (existingProductIndex !== -1) {

                    quatilyData = cartItems[existingProductIndex].quantity;
                }
                let obj = {
                    "productId": element.product_id,
                    "quantity": element.quantity
                }
                arrayControl.push(obj);
            });
            // cookies.set('cartItems', []);
            cookies.set('cartItems', arrayControl);

            this.getCookiesCartList();
        }
    }

    async cartItemDelete(index) {
        if (this.state.isUserLogin) {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/cart/delete/${this.state.data[index].cartId}/${this.state.userId}`, {
                method: 'POST'
            });
            const dataList = await response.json();
            this.fetchData(this.state.userId);
        } else {
            let arrayControl = [];
            const cookies = new Cookies();
            let cartItems = cookies.get('cartItems') || [];
            this.state.data.forEach(element => {
                let existingProductIndex = cartItems.findIndex(item => item.productId === element.product_id);
                if (index != existingProductIndex) {
                    let quatilyData = 1;
                    if (existingProductIndex !== -1) {

                        quatilyData = cartItems[existingProductIndex].quantity;
                    }
                    let obj = {
                        "productId": element.product_id,
                        "quantity": quatilyData
                    }
                    arrayControl.push(obj);
                }
            });
            // cookies.set('cartItems', []);
            cookies.set('cartItems', arrayControl);

            this.getCookiesCartList();
        }
    }

    render() {
        const { data } = this.state;

        return (
            <section className="cart-section pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className='col-md-8'>
                            <div className="w-100 table-responsive mb-60">
                                <table className="table cw-cart-table mb-0">
                                    <thead>
                                        <tr>
                                            <th />
                                            <th scope="col" className="product-name">Product</th>
                                            <th scope="col" className="product-qty">Quantity</th>
                                            <th scope="col" className="product-price">Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, i) => (
                                            <tr key={i}>
                                                <td className="product-remove text-center cw-align">
                                                    <Link to="#"><i className="fas fa-times" onClick={() => this.cartItemDelete(i)} /></Link>
                                                </td>
                                                <td data-title="Product" className="has-title">
                                                    <div className="product-thumbnail">
                                                        <img src={item.product_parent_image} alt="product_thumbnail" />
                                                    </div>
                                                    <Link to="/shop-detail">{item.product_name}</Link>
                                                </td>
                                                <td className="quantity shop-detail-content cw-qty-sec cw-align has-title" data-title="Quantity">
                                                    <div className="quantity-box">
                                                        <button type="button" className="minus-btn" onClick={() => this.DecreaseItem(i)}>
                                                            <i className="fal fa-minus" />
                                                        </button>
                                                        <input type="text" className="input-qty" name="name" value={item.quantity} onChange={this.handleChange.bind(this)} readOnly />
                                                        <button type="button" className="plus-btn" onClick={() => this.IncrementItem(i)}>
                                                            <i className="fal fa-plus" />
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="product-price text-white cw-align has-title" data-title="Price">
                                                    <span className="product-currency"><b>Rs.</b></span> <span className="product-amount"><b>{item.totalMultiplyPrice}</b></span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={4}>
                                                <button className="main-btn btn-filled float-left">Continue Shoping</button>
                                                <button className="main-btn btn-filled float-right" onClick={this.cartUpdate}>Update Cart</button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className="cw-product-promo">
                                <div className="cw-title">
                                    <h5>Discount Code</h5>
                                </div>
                                <form>
                                    <div className="form-group mb-0">
                                        <label htmlFor="couponCode">Enter coupon code</label>
                                        <input type="text" className="form-control" placeholder="Coupon Code" id="couponCode" />
                                        <button type="submit" className="main-btn btn-filled mt-4">Apply</button>
                                    </div>
                                </form>
                            </div>
                            {/* {data.map((item, i) => ( */}
                            <div className="cw-product-promo">
                                <table className="table cw-table-borderless">
                                    <tbody>
                                        <tr>
                                            <td> <b>Subtotal</b> </td>
                                            <td className="text-right">Rs. {this.state.totalPrice}</td>
                                        </tr>
                                        <tr>
                                            <td> <b>Shipping</b> </td>
                                            <td className="text-right">Rs. 2.99</td>
                                        </tr>
                                        <tr>
                                            <td> <b>Total</b> </td>
                                            <td className="text-right">Rs. {(Number(this.state.totalPrice) + Number(2.99)).toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link to="/checkout" className="main-btn btn-filled w-100">Proceed to Checkout</Link>
                            </div>
                            {/* ))} */}

                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.customers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addCartList: (data) => dispatch(addCartList(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);

// export default Content;