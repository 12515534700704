import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import img1 from '../../../assets/img/others/bestsale.jpg';



class Harvestinfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            clicks: 1,
            show: true,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }
    IncrementItem = () => {
        this.setState({ clicks: this.state.clicks + 1 });
    };

    DecreaseItem = () => {
        if (this.state.clicks < 1) {
            this.setState({
                clicks: 0,
            });
        } else {
            this.setState({
                clicks: this.state.clicks - 1,
            });
        }
    };
    handleChange(event) {
        this.setState({ clicks: event.target.value });
    }
    render() {
        return (
            <section className="Shop-section pt-120 pb-120">
                <div className="container">
                    <h1 className='text-center mb-70'>Own your Desires</h1>
                    <div className="harvest-section row justify-content-center">
                        <div className="col-lg-6">
                            <div className="shop-detail-image">
                                <div className="image-box">
                                    <Link to="#">
                                        <img src={img1} className="img-fluid" alt="img" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="shop-detail-content align-items-center">
                                <div className="harvest-title-text mb-20">
                                    <p>Turn your jewellery wish list into reality
                                        With our</p>
                                </div>
                                {/* <h4 className='page-title text-center mb-20'>Turn your jewellery wish list into a reality
                                    With our</h4> */}
                                <h1 className="harvest-title page-title text-center mb-20">Milli Harvest</h1>
                                <div className="harvest-tag desc mb-20 pb-20 mt-50 border-bottom d-flex text-center">
                                    <h1 className='section-title col-6 text-center pl-100'>10 + 1</h1>
                                    <h3 className='col-6 text-center pr-100'>Monthly Installment Plan</h3>
                                </div>
                                <div className="harvest-text mb-20">
                                    <p>Pay 10 Installments, get 100% 0ff on 11th installment!</p>
                                </div>
                                <div className="harvest-text mb-20">
                                    <p>With Every month Play & win Discount!</p>
                                </div>
                                {/* <div className="harvest-text-tag mb-20">
                                    <p>*Redeemable from 6th Month</p>
                                </div> */}
                            </div>
                        </div>
                        <div className='form-section col-12 mt-100'>
                            <div className='form-text'>
                                <p>You are eligible for</p>
                                <h2>50% Discount</h2>
                                <p>on your first installment</p>
                            </div>
                            <div className='row'>
                                <div className="widget search-widget col-5 mt-30">
                                    <form action="#">
                                        <input type="text" placeholder="Enter Monthly Amount" />
                                        <button className='main-btn btn-filled monthly' type="submit">You will pay<br></br>only 1000</button>
                                    </form>
                                </div>
                                <div className="widget search-widget col-7 mt-30">
                                    <form action="#">
                                        <input className='col-12' type="text" placeholder="E-mail is required" />
                                        <button className='main-btn btn-filled start' type="submit">Start Now</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <h2 className='text-center mt-100'>Why Milli Harvest plan</h2>
                        <div className='row col-12 mt-50 text justify-content-center'>
                            <div className='col-4 text-center'>
                                <h3>Plan Ahead</h3>
                                <p className='ml-10 mr-10 mt-3'>Subscribe to plan for your
                                    future high value purchases</p>
                            </div>
                            <div className='col-4 text-center'>
                                <h3>For Special Moments</h3>
                                <p className='ml-10 mr-10 mt-3'>Plan for gifting on special
                                    occasions like Birthdays, Weddings etc</p>
                            </div>
                            <div className='col-4 text-center'>
                                <h3>Special Discounts</h3>
                                <p className='ml-10 mr-10 mt-3'>Pay 10 installments & get 100%
                                    discount on the 11th installment</p>
                            </div>
                        </div>
                        <h2 className='text-center mt-100'>How does it work?</h2>
                        <div className='col-12 mt-50'>
                            <div className='how-it-work'>
                                <div className='oval'>
                                    <div className='oval-container'>
                                        <span class="oval-text">3 easy steps</span>
                                        <span class="oval-content">to purchase the jewellery your heart desires</span>
                                    </div>
                                </div>
                                <div class="description">
                                    <div class="outer-wrapper clearfix">
                                        <span class="step-bar">1</span>
                                        <span class="step-lines"></span>
                                        <span class="outer">
                                            <div class="inner">
                                                <span class="pay-monthly"></span>
                                            </div>
                                        </span>
                                        <span class="text-content">
                                            <div class="feature-title">
                                                Pay Monthly
                                            </div>
                                            <div class="feature-content">
                                                10 monthly installments with easy payment options
                                            </div>
                                        </span>
                                    </div>
                                    <div class="outer-wrapper Discount clearfix">
                                        <span class="step-bar">2</span>
                                        <span class="step-lines"></span>
                                        <span class="outer"> <div class="inner">
                                            <span class="discount"></span>
                                        </div>
                                        </span>
                                        <span class="text-content">
                                            <div class="feature-title">Get Special Discounts
                                            </div>
                                            <div class="feature-content">Get 50% off on the first month and 100% off the 11th month
                                            </div>
                                        </span>
                                    </div>
                                    <div class="outer-wrapper">
                                        <span class="step-bar">3</span>
                                        <span class="step-lines"></span>
                                        <span class="outer">
                                            <div class="inner">
                                                <span class="redeem"></span>
                                            </div>
                                        </span>
                                        <span class="text-content">
                                            <div class="feature-title">Happy Shopping
                                            </div>
                                            <div class="feature-content">Use the auto-redeemed voucher provided to you on the date of<br></br>11th installment and buy at any of our stores / online.
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Harvestinfo;