import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import loginbg from '../../../assets/img/login.jpg';
import Cookies from 'universal-cookie';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registrationSuccess: false,
            redirectToHome: false,
            errorMessage: ''
        };
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const cookies = new Cookies();
        let cartItems = cookies.get('cartItems') || [];
        if (cartItems.length > 0) {
            formData.append('cartItems', JSON.stringify({ cartItems }));
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +'/api/auth/login', {
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            if (response.ok && data.message == 'Login Succesful') {
                // Save userId and userName to local storage

                localStorage.setItem('userData', JSON.stringify({
                    userId: data.userId,
                    userName: data.userName
                }));

                cookies.set('cartItems', []);
                // Redirect to home page
                this.setState({ redirectToHome: true });
            } else {
                // Login failed
                // const data = await response.json();
                this.setState({ errorMessage: data.message || 'An error occurred while logging in. Please try again later.' });
            }
        } catch (error) {
            console.error('Error logging in:', error);
            this.setState({ errorMessage: 'An error occurred while logging in. Please try again later.' });
        }
    };

    componentDidMount() {
        // Check if registration success flag is set in local storage
        const registrationSuccess = localStorage.getItem('registrationSuccess');
        if (registrationSuccess === 'true') {
            // Clear the flag from local storage
            localStorage.removeItem('registrationSuccess');
            // Set the state to indicate registration success
            this.setState({ registrationSuccess: true });
        }
        // Check if user is logged in
        const userData = localStorage.getItem('userData');
        if (userData) {
            this.setState({ redirectToHome: true });
        }
    }

    render() {
        const { redirectToHome, errorMessage } = this.state;

        if (redirectToHome) {
            return <Redirect to="/" />;
        }

        // Retrieve userId and userName from local storage
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData ? userData.userId : null;
        const userName = userData ? userData.userName : null;

        return (
            <section className="login-sec pt-120 pb-120">
                <div className="container">
                    <div className="account-wrapper">
                        <div className="row no-gutters">
                            <div className="col-lg-6">
                                <div className="login-content" style={{ backgroundImage: `url(${loginbg})` }}>
                                    <div className="description text-center">
                                        <h2>Oops !</h2>
                                        <p className="text-white">"It may happen sometimes. But we don't let you worry."</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="login-form">
                                    <h2>Forgot Password !</h2>
                                    <p className='text-white mb-4'>Enter your registered e-mail address and we will send you OTP.</p>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="input-group input-group-two mb-20">
                                            <input type="mail" placeholder="E-mail Id" name="username" />
                                        </div>
                                        <Link to="/otp">
                                            <button type="submit" className="main-btn btn-filled mt-20 login-btn mb-3">Submit</button>
                                        </Link>
                                        <Link to="/login">Back to Log-in</Link>
                                        {errorMessage && (
                                            <div className="alert alert-danger" role="alert">
                                                {errorMessage}
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Content;
