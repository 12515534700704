import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import ppimg1 from '../../assets/img/recent-post-wid/04.png';
import ppimg2 from '../../assets/img/recent-post-wid/05.png';
import ppimg3 from '../../assets/img/recent-post-wid/06.png';

import insta1 from '../../assets/img/instagram-wid/01.jpg';
import insta2 from '../../assets/img/instagram-wid/02.jpg';
import insta3 from '../../assets/img/instagram-wid/03.jpg';
import insta4 from '../../assets/img/instagram-wid/04.jpg';
import insta5 from '../../assets/img/instagram-wid/05.jpg';
import insta6 from '../../assets/img/instagram-wid/06.jpg';
import insta7 from '../../assets/img/instagram-wid/07.jpg';
import insta8 from '../../assets/img/instagram-wid/08.jpg';
import insta9 from '../../assets/img/instagram-wid/09.jpg';
import Cookies from 'universal-cookie';
// Popular Products
const popularproducts = [
    { img: ppimg1, title: 'Golden Pendant.', price: '500', discountprice: '580' },
    { img: ppimg2, title: 'Silver Pendant.', price: '400', discountprice: '520' },
    { img: ppimg3, title: 'Diamond Ring.', price: '390', discountprice: '450' },
];
// Cart Products
const cartproducts = [
    { img: ppimg1, title: 'Golden Pendant.', price: '500', discountprice: '580' },
    { img: ppimg2, title: 'Silver Pendant.', price: '400', discountprice: '520' },
    { img: ppimg3, title: 'Diamond Ring.', price: '390', discountprice: '450' },
];
// Color selection
const colorposts = [
    { name: 'Red' },
    { name: 'Green' },
    { name: 'Brown' },
    { name: 'Grey' },
    { name: 'Orange' },
];
// Instagram Feeds
const instafeeds = [
    { img: insta1 },
    { img: insta2 },
    { img: insta3 },
    { img: insta4 },
    { img: insta5 },
    { img: insta6 },
    { img: insta7 },
    { img: insta8 },
    { img: insta9 },
];
// Tags
const tags = [
    { title: 'Rings' },
    { title: 'earrings' },
    { title: 'necklace' },
    { title: 'bracelets' },
    { title: 'wedding ring' },
    { title: 'bangles' },
    { title: 'hard ring' },
    { title: 'ankle bracelet' },
    { title: 'silver bracelet' },
    { title: 'earring' },
    { title: 'copper bracelet' },
    { title: 'tech' },
];
class Shopsidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            totalPrice: 0
        };
    }

    componentDidMount() {
        const isLoggedIn = JSON.parse(localStorage.getItem('userData'));
        if (isLoggedIn) {
            this.fetchData(isLoggedIn.userId);
        } else {
            this.getCookiesCartList();
        }
    }

    fetchData = async (userIdControl) => {
        try {
            if (!userIdControl) userIdControl = '1';
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/cart/find-by-user-id/${userIdControl}`);
            const data = await response.json();
            let totalPriceDtl = 0;
            data.forEach(element => {
                element['totalMultiplyPrice'] = Number(element.product_price) * Number(element.quantity);
                totalPriceDtl = totalPriceDtl + element.totalMultiplyPrice;
            });
            this.setState({ data: data, totalPrice: totalPriceDtl.toFixed(2) });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    getCookiesCartList() {
        const cookies = new Cookies();
        let cartItems = cookies.get('cartItems') || [];
        let idControl = '';
        cartItems.forEach(element => {
            idControl = idControl + ',' + element.productId;
        });
        // this.setState({ cartItems });
        if (cartItems.length > 0) {
            this.getProductListById(idControl.slice(1, idControl.length), cartItems);
        } else {
            this.setState({ data: [] });
        }
    }

    getProductListById = async (idControl, cartItems) => {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/product/get_product_detail?cartList=${idControl}`, {
            method: 'POST',
            // body: apiData
        });
        const dataList = await response.json();
        let totalPriceDtl = 0;
        dataList.forEach(element => {
            let quantityControl = cartItems?.filter((data) => {
                return data.productId == element.product_id
            });
            element['totalMultiplyPrice'] = Number(element.product_price) * Number(quantityControl[0]?.quantity);
            totalPriceDtl = totalPriceDtl + element.totalMultiplyPrice;
        });
        this.setState({ data: dataList, totalPrice: totalPriceDtl.toFixed(2) });
    }


    render() {
        return (
            <div className="sidebar">
                {/* About Author Widget */}
                {/* Search Widget */}
                <div className="widget search-widget mb-40">
                    <h5 className="widget-title">Search Objects</h5>
                    <form action="#">
                        <input type="text" placeholder="Search your keyword..." />
                        <button type="submit"><i className="far fa-search" /></button>
                    </form>
                </div>
                <div className="widget popular-feeds mb-40">
                    <h5 className="widget-title">Custom Design</h5>
                    <div className="popular-feed-loop">
                        <div className="widget-cart-info">
                            <div className="custom-text">
                                <span>Customize your Jewel as you Desire</span>
                            </div>
                            <div className="cart-buttons">
                                <Link to="/custom-design" className="main-btn btn-filled">Customize</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Popular Post Widget */}
                {/* <div className="widget popular-feeds mb-40">
                    <h5 className="widget-title">Popular Products</h5>
                    <div className="popular-feed-loop">
                        {popularproducts.map((item, i) => (
                            <div key={i} className="single-popular-feed">
                                <div className="feed-img">
                                    <img src={item.img} alt="" />
                                </div>
                                <div className="feed-desc desc">
                                    <h6><Link to="/shop-detail">{item.title}</Link></h6>
                                    <span className="price">${item.price} <span>${item.discountprice}</span></span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div> */}
                {/* Cart Widget */}
                <div className="widget popular-feeds mb-40">
                    <h5 className="widget-title">Cart</h5>
                    <div className="popular-feed-loop">
                        {this.state.data.map((item, i) => (
                            <div key={i} className="single-popular-feed">
                                <div className="feed-img">
                                    <img src={item.product_parent_image} alt="" />
                                </div>
                                <div className="feed-desc desc">
                                    <div className="cart-flex">
                                        <h6><Link to="#">{item.product_name}</Link></h6>
                                        <Link to="#" className="remove"><i className="fal fa-times" /></Link>
                                    </div>
                                    <span className="price">${item.totalMultiplyPrice} <span>${item.product_offer_price}</span></span>
                                </div>
                            </div>
                        ))}
                        <div className="widget-cart-info">
                            <div className="cart-details">
                                <span>Subtotal:</span>
                                <span>{this.state.totalPrice}</span>
                            </div>
                            <div className="cart-buttons">
                                <Link to="/cart" className="main-btn btn-filled">View Cart</Link>
                                <Link to="/checkout" className="main-btn btn-filled">Checkout</Link>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Color Widget */}
                <div className="widget socail-widget mb-40">
                    <h5 className="widget-title">Color</h5>
                    <div className="filter-color">
                        <form>
                            {colorposts.map((item, i) => (
                                <label key={i} className="checkbox">
                                    <input type="checkbox" name="#" />
                                    <span className="custom-box" />
                                    {item.name}
                                </label>
                            ))}
                        </form>
                    </div>
                </div>
                {/* Instagram Feeds Widget */}
                {/* <div className="widget instagram-feed-widget mb-40">
                    <h5 className="widget-title">Instagram Feeds</h5>
                    <ul>
                        {instafeeds.map((item, i) => (
                            <li key={i}><img src={item.img} alt="" /></li>
                        ))}
                    </ul>
                </div> */}
                {/* Popular Tags Widget */}
                {/* <div className="widget popular-tag-widget">
                    <h5 className="widget-title">Popular Tags</h5>
                    <ul>
                        {tags.map((item, i) => (
                            <li key={i}><Link to="#">{item.title}</Link></li>
                        ))}
                    </ul>
                </div> */}
            </div>
        );
    }
}

export default Shopsidebar;