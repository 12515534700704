import React, { Component } from 'react';

class Content extends Component {
    render() {
        return (
            <section className="legal-section pt-115 pb-115">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="legal-wrapper">
                                <div className="legal-content">
                                    <h3>Introduction</h3>
                                    <p className='mb-3'>We, MilliJewel.com ("MillJewel", "we", "us" or "our"), carry out our business from our retail stores (under our brand "Millijewel.com Jewellery") and our website (www.Millijewel.com.com) and our mobile app (Millijewel.com Jewellery Online) (collectively "Platform"). At Millijewel.com, we respect your privacy and are committed to taking reasonable precautions to protect your information and comply with our obligations related to privacy.</p>
                                    <p className='mb-3'>This policy ("Privacy Policy") outlines how your information is collected by us through various interactions with you on the Platform, and how the information so collected is used by us.</p>
                                    <p className='mb-3'>Please note that this Privacy Policy may be amended or updated from time to time to reflect changes in our practices concerning the processing of personal data or changes in applicable law. We encourage you to read this Privacy Policy carefully and to regularly check it to review any changes that we might make to it.</p>
                                    <p>By accessing the services provided by the Platform, you agree to the collection, use, sharing, and storage of your information by Millijewel.com in the manner provided in this Privacy Policy.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Collection of Information</h3>
                                    <p className='mb-3'>We collect the details provided by you on registration (if any) together with information we learn about you from your use of our service and your visits to the Platform.</p>
                                    <p className='mb-3'>We may collect the following personally identifiable information about you when you are transacting on the Platform:</p>
                                    <ul className='mb-3'>
                                        <li className='ml-5' type='disc'>First and last name;</li>
                                        <li className='ml-5' type='disc'>Email addresses;</li>
                                        <li className='ml-5' type='disc'>Contact details including mobile phone numbers;</li>
                                        <li className='ml-5' type='disc'>PIN/ZIP code;</li>
                                        <li className='ml-5' type='disc'>Demographic profile (such as your age, gender, date of birth, date of the anniversary, date of spouse's birthday, and address); and</li>
                                        <li className='ml-5' type='disc'>Your opinion on services, products, features, etc. on our Platform;</li>
                                    </ul>
                                    <p className='mb-3'>We also collect certain data as you access and use our services on the Platform which include the following:</p>
                                    <ul className='mb-3'>
                                        <li className='ml-5' type='disc'>Device information;</li>
                                        <li className='ml-5' type='disc'>Domain server;</li>
                                        <li className='ml-5' type='disc'>Location information;;</li>
                                        <li className='ml-5' type='disc'>PIP address;</li>
                                        <li className='ml-5' type='disc'>Type of web browser you are using;</li>
                                        <li className='ml-5' type='disc'>Network carrier.</li>
                                    </ul>
                                    <p className='mb-3'>We may also collect information about:</p>
                                    <ul className='mb-3'>
                                        <li className='ml-5' type='disc'>The pages you visit/access;</li>
                                        <li className='ml-5' type='disc'>The links you click on our Platform;;</li>
                                        <li className='ml-5' type='disc'>The number of times you access the page; and</li>
                                        <li className='ml-5' type='disc'>Things you view, add to bag, add to wish list.</li>
                                    </ul>
                                    <p className='mb-3'>If you make a purchase, we collect sensitive personal data in connection with the purchase. This data includes your payment data, such as your credit or debit card number other card information or bank/ account details, and other account and authentication information, as well as billing, shipping, and contact details.</p>
                                    <p>We may collect additional information in connection with your participation in any schemes, promotions, or contests offered by us and information you provide when giving us feedback or completing profile forms. We may also monitor customer traffic patterns and Platform use, which enable us to improve the service we provide to customers.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Use of Information</h3>
                                    <p className="mb-3">We use personal information to provide the products and services on the Platform and for general business purposes. Collection of email enables us to improve your shopping experience. Further, we use your personal information and your contact information to send you registration confirmation, special offers, recommendations (based on your previous orders, your browsing history and your interests), changes in the service policies or terms of use, event-based communications such as order information, renewal notices, invites, reminders, etc., to troubleshoot problems, to collect monies owed, to measure interest in our products and services, and to inform you about online and offline offers and customize your experience. In our efforts to continually improve our product and service offerings, we analyse demographic information and profile data about users' activity on the Platform. We identify and use your IP address to help diagnose problems with our server and to administer the Platform.</p>
                                    <p>All personal data and information collected by us is used only for the purpose specified in this Privacy Policy.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Cookies and similar technologies</h3>
                                    <p className="mb-3">We may use cookies and other technologies to provide, protect, and improve our products and services, such as by personalizing content, offering and measuring advertisements, understanding user behavior, and providing a safer experience.</p>
                                    <p className="mb-3">You can remove or reject cookies using your browser or device settings, but in some cases doing so may affect your ability to use the Platform/ our services.</p>
                                    
                                </div>
                                <div className="legal-content">
                                    <h3>Disclosure of Personal Information</h3>
                                    <p className="mb-3">We may share your personal information (on a need-to-know basis) with third parties that provide services on our behalf and/or generally in connection with our Platform offerings, such as website hosting, email services, marketing, fulfilling customer orders, transaction processing, data analytics, providing customer service, and conducting customer research and satisfaction surveys. These service providers are obligated to protect your data under the law.</p>
                                    <p className='mb-3'>We may also disclose personal information such as name, email, mobile phone number, device information, location, network carrier, etc. available to certain third-party service providers, on a need-to-know basis. This information is shared with the third-party service providers so that we can (a) personalize the Platform for you and (b) perform behavioural analytics.</p>
                                    <p className='mb-3'>We reserve the right to disclose your personal identifiable information as required by law and/or when we believe that disclosure is necessary to protect our rights and/or comply with a judicial proceeding, legal process or a court order served on us.</p>
                                    <p>We do not sell, share or distribute any personal information with third parties for their own marketing or advertising purposes without your prior express consent.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Your Consent</h3>
                                    <p>By using our Platform and/or by providing your information, you consent to the collection, sharing, storage and use of the information you disclose on the Platform in accordance with the Privacy Policy, including but not limited to your consent for sharing of your information as per this Privacy Policy.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Link to other websites</h3>
                                    <p>Our Platform may contain links to other websites. However, please note that once you have used these links to leave our site, we do not have control over those websites. We are not responsible for the protection and privacy of any information that you provide whilst visiting such sites. This Privacy Policy does not govern such sites. Please exercise caution and look at the privacy policy applicable to the website in question prior to sharing any personal information</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Your Rights</h3>
                                    <p className='mb-3'>You have the right to access or correct the personal information that we collect. You are also entitled to restrict or object, at any time, to the further processing of your personal information. You may write to us at customer.grievances@Millijewel.com.com or cs@Millijewel.com.com regarding the personal information collected by us.</p>
                                    <p className='mb-3'>If you believe that any information we are holding on you is incorrect or incomplete, you may write to us at the above-mentioned email address. We will promptly correct any information which is incorrect in our records.</p>
                                    <p className='mb-3'>For any of the uses of your personal information described in the Privacy Policy that require your consent, note that you may withdraw your consent by writing to us at the above-mentioned email address. In the event you refuse to share any information or withdraw consent to process information that you have previously given to us, we reserve the right to restrict or deny access to the Platform and the provision of our services for which we consider such information to be necessary.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Data Retention</h3>
                                    <p>We store and retain the personal data provided by the users for such period of time as is required to fulfil the purposes for which such information is collected, as outlined in this Privacy Policy, subject to such longer periods of retention as may be required under applicable laws.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Security Precautions</h3>
                                    <p>Our Platform has in place security measures and standards (as required under applicable law) to protect the loss, misuse, and alteration of the information under our control. Whenever you access your registered account on the Platform or process a transaction on our Platform, we offer the use of a secure server. Please beware that, despite our best efforts, no security system is impenetrable.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Choice/Opt-Out</h3>
                                    <p>Our Platform provides all users with the opportunity to opt out of receiving non-essential (promotional, marketing-related) communications from us on behalf of our partners, and from us in general, after setting up an account. You may delete certain non-mandatory information. You can write to us at the contact information provided below to assist you with these requests.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Profile Deactivation</h3>
                                    <p>Our Platform enables you to temporarily freeze your profile. This temporary action will leave you without a Millijewel.com account and access to speedy checkout, information about your past orders, wishlists, Gold Mine, Vouchers, Credits, Cash, and all other personalized Millijewel.com products and services.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Profile Deletion</h3>
                                    <p>Our Platform enables you to request to erase your Millijewel.com account permanently. Once requested to delete, all associated information like past orders, past/current Try at Home bookings, wishlists, saved addresses, and any pending benefits like MIlli Harvest 10+1 installments, Vouchers, Cash, or Credits will no longer be accessible to you. The 'Profile Deletion' will require you to forfeit future account creation with the same email address, any claims to policies like Lifetime Exchange & Buyback, 30-Day Returns, and Refund, product cleaning & fixing, and any other policies or services offered by us as well as any claims stemming from product damages and related issues. In the event that your account has any open orders, payments, grievances, shipments, or installment plans, then we may refuse or delay the deletion of your account.</p>
                                </div>
                                <div className="legal-content">
                                    <h3>Grievance Officer</h3>
                                    <p>Millijewel.com has appointed Mr. Sunil Joseph as the Grievance Officer. Any complaints or grievances relating to processing of the personal data should be sent to the Grievance Officer at customer@Millijewel.com.com or </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section>

        );
    }
}

export default Content;