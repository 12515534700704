import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import 'magnific-popup';

import img1 from '../../../assets/img/others/craft.jpg';
import img2 from '../../../assets/img/others/trust.jpg';
import img3 from '../../../assets/img/others/luxuary.jpg';
import img4 from '../../../assets/img/others/family.jpg';
import teamimg1 from '../../../assets/img/team/img1.jpeg';
import teamimg2 from '../../../assets/img/team/img2.jpeg';
import teamimg3 from '../../../assets/img/team/img31.jpg';
import teamimg4 from '../../../assets/img/team/img4.jpg';
import teamimg5 from '../../../assets/img/team/img5.jpg'



class About extends Component {

    render() {
        return (
            <section className="about-section pt-115 pb-115">
                <div className='abouts-section'>
                    <div className="container">
                        <div className='justify-content-center mt-50'>
                            <h2 className='text-center mb-5'>Welcome to Milli</h2>
                            <h4 className='text-center mb-5'>Where Luxury Meets Accessibility and Trust</h4>
                            <p className='text-center p-30'>At Milli, we believe that jewelry is more than just adornment -it's a reflection of your unique style, personality, and the moments
                                that matter most in life. That's why we're committed to redefining the jewelry shopping experience, making luxury accessible to all
                                without compromising on quality or integrity.
                            </p>
                        </div>
                        <div className='col-12 align-items-center row mt-70'>
                            <div className="d-block d-sm-none">
                                <div className="shop-detail-image">
                                    <div className="image-box">
                                        <Link to="#">
                                            <img src={img1} className="img-fluid" alt="img" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-6">
                                <h4 className='mt-5 mb-3'>Crafted with Care:</h4>
                                <p>Each piece in the Milli collection is a testament to the artistry and dedication of our master craftsmen. With meticulous attention to
                                    detail and a passion for perfection, we create jewelry that transcends trends, becoming timeless treasures to cherish for a lifetime.</p>
                            </div>
                            <div className="col-lg-3 col-md-6 d-none d-sm-block">
                                <div className="shop-detail-image">
                                    <div className="image-box">
                                        <Link to="#">
                                            <img src={img1} className="img-fluid" alt="img" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 align-items-center row mt-70'>
                            <div className="col-lg-3 col-md-6">
                                <div className="shop-detail-image">
                                    <div className="image-box">
                                        <Link to="#">
                                            <img src={img3} className="img-fluid" alt="img" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-6">
                                <h4 className='mt-5 mb-3'>Accessible Luxury:</h4>
                                <p>We believe that everyone deserves to indulge in the luxury of fine jewelry. That's why we've curated a collection that combines
                                    opulence with affordability, offering high-quality pieces at prices that won't break the bank. From classic diamond rings to
                                    contemporary statement pieces, there's something for every style and occasion at Milli.</p>
                            </div>
                        </div>
                        <div className='col-12 align-items-center row mt-70'>
                            <div className="d-block d-sm-none">
                                <div className="shop-detail-image">
                                    <div className="image-box">
                                        <Link to="#">
                                            <img src={img2} className="img-fluid" alt="img" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-6">
                                <h4 className='mt-5 mb-3'>Transparency and Trust:</h4>
                                <p>At Milli, transparency is at the heart of everything we do. We uphold the highest ethical standards in sourcing our materials,
                                    ensuring that every gemstone and metal used in our jewelry is responsibly obtained and of the finest quality. Our commitment to
                                    customer satisfaction extends to our policies, including a lifetime exchange/buyback program and a 15-day money-back guarantee,
                                    providing you with peace of mind and confidence with every purchase.</p>
                            </div>
                            <div className="col-lg-3 col-md-6 d-none d-sm-block">
                                <div className="shop-detail-image">
                                    <div className="image-box">
                                        <Link to="#">
                                            <img src={img2} className="img-fluid" alt="img" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 align-items-center row mt-70'>
                            <div className="col-lg-3 col-md-6 col-sm-12">
                                <div className="shop-detail-image">
                                    <div className="image-box">
                                        <Link to="#">
                                            <img src={img4} className="img-fluid" alt="img" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-6">
                                <h4 className='mt-5 mb-3'>Join the Milli Family:</h4>
                                <p>Whether you're celebrating a special moment or treating yourself to a little luxury, we invite you to explore the world of Milli. Shop
                                    our collection online or visit one of our boutique stores to experience the elegance, craftsmanship, and trust that define the Milli
                                    brand. Welcome to a world where luxury is within reach—welcome to Milli.</p>
                            </div>
                        </div>
                        {/* <h2 className='text-center mt-150'>Team</h2> */}
                        {/* <div className='row mt-30 justify-content-center'> */}
                            {/* <div className='col-lg-4 col-sm-6 col-xs-12 mt-5 pr-5 image-box'>
                                <img src={teamimg1} alt="img" />
                                <h4 className='text-center mt-30'>Sunil Joseph</h4>
                            </div> */}
                            {/* <div className='col-4 mt-5 pr-5 image-box'>
                                <img src={teamimg2} alt="img" />
                                <h4 className='text-center mt-30'>Divya Joseph</h4>
                            </div> */}
                            {/* <div className='col-lg-4 col-sm-6 col-xs-12 mt-5 pr-5 image-box'>
                                <img src={teamimg3} alt="img" />
                                <h4 className='text-center mt-30'>Seenivasagam Chellaiah</h4>
                            </div> */}
                            {/* <div className='col-4 mt-5 pr-5 image-box'>
                                <img src={teamimg4} alt="img" />
                                <h4 className='text-center mt-30'>Rakesh NR</h4>
                            </div> */}
                            {/* <div className='col-4 mt-5 pr-5 image-box'>
                                <img src={teamimg5} alt="img" />
                                <h4 className='text-center mt-30'>Nikita</h4>
                            </div> */}
                        {/* </div> */}
                        <h2 className='text-center mt-150'>The Milli Promise</h2>
                        <div className="row mt-100">
                            <div className="col-lg-3 col-md-6">
                                <div className="service-wrapper style-2 mb-30">
                                    <div className="service-content text-center">
                                        <div className="icon">
                                            <i className="fal fa-diamond" />
                                        </div>
                                        <h5>Ethically Sourced</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="service-wrapper style-2 mb-30">
                                    <div className="service-content text-center">
                                        <div className="icon">
                                            <i className="fal fa-badge" />
                                        </div>
                                        <h5>Sealed in Assurance</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="service-wrapper style-2 mb-30">
                                    <div className="service-content text-center">
                                        <div className="icon">
                                            <i className="fal fa-ring" />
                                        </div>
                                        <h5>Upgrade yor Jewellery</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="service-wrapper style-2 mb-30">
                                    <div className="service-content text-center">
                                        <div className="icon">
                                            <i className="fal fa-gem" />
                                        </div>
                                        <h5>Milli Product</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        );
    }
}

export default About;