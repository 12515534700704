import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
// import loginbg from '../../../assets/img/login.jpg';
import Cookies from 'universal-cookie';

class Content extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registrationSuccess: false,
            redirectToHome: false,
            errorMessage: ''
        };
    }

    showReset = () => {
        this.setState({ password: !this.state.password });
        this.handleCheckboxChange("Other");
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const cookies = new Cookies();
        let cartItems = cookies.get('cartItems') || [];
        if (cartItems.length > 0) {
            formData.append('cartItems', JSON.stringify({ cartItems }));
        }
        try {
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +'/api/auth/login', {
                method: 'POST',
                body: formData
            });
            const data = await response.json();
            if (response.ok && data.message == 'Login Succesful') {
                // Save userId and userName to local storage

                localStorage.setItem('userData', JSON.stringify({
                    userId: data.userId,
                    userName: data.userName
                }));

                cookies.set('cartItems', []);
                // Redirect to home page
                this.setState({ redirectToHome: true });
            } else {
                // Login failed
                // const data = await response.json();
                this.setState({ errorMessage: data.message || 'An error occurred while logging in. Please try again later.' });
            }
        } catch (error) {
            console.error('Error logging in:', error);
            this.setState({ errorMessage: 'An error occurred while logging in. Please try again later.' });
        }
    };

    componentDidMount() {
        // Check if registration success flag is set in local storage
        const registrationSuccess = localStorage.getItem('registrationSuccess');
        if (registrationSuccess === 'true') {
            // Clear the flag from local storage
            localStorage.removeItem('registrationSuccess');
            // Set the state to indicate registration success
            this.setState({ registrationSuccess: true });
        }
        // Check if user is logged in
        const userData = localStorage.getItem('userData');
        if (userData) {
            this.setState({ redirectToHome: true });
        }
    }

    render() {
        const { redirectToHome, errorMessage } = this.state;

        if (redirectToHome) {
            return <Redirect to="/" />;
        }

        // Retrieve userId and userName from local storage
        const userData = JSON.parse(localStorage.getItem('userData'));
        const userId = userData ? userData.userId : null;
        const userName = userData ? userData.userName : null;

        return (
            <section className="login-sec pt-120 pb-120">

                {/* <div className="container d-flex justify-content-center">
                    <div className="col-lg-6">
                        <div className="otp-form">
                            <h2>Enter OTP</h2>
                            <p className='text-white mb-4'>Enter the One time password sent to your e-mail.</p>
                            <form onSubmit={this.handleSubmit}>
                                <div className='row mb-3'>
                                    <div className="col-3 otp-input mb-20">
                                        <input type="text" placeholder=" " name=" " required />
                                    </div>
                                    <div className="col-3 otp-input mb-20">
                                        <input type="text" placeholder=" " name=" " required />
                                    </div>
                                    <div className="col-3 otp-input mb-20">
                                        <input type="text" placeholder=" " name=" " required />
                                    </div>
                                    <div className="col-3 otp-input mb-20">
                                        <input type="text" placeholder=" " name=" " required />
                                    </div>
                                </div>
                                <Link to="/reset-password">
                                    <button type="submit" className="main-btn btn-filled mt-20 login-btn mb-3">Validate OTP</button>
                                </Link>
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div> */}

                <div className="container d-flex justify-content-center">
                    <div className="col-lg-6">
                        <div className="otp-form">
                            <h2>Reset Password</h2>
                            <p className='text-white mb-4'>Create a new password.</p>
                            <form onSubmit={this.handleSubmit}>
                                <div className="otp-input mb-5">
                                    <input className='mb-5' type="text" placeholder="Enter New Password" name=" " />
                                    <input type="text" placeholder="Confirm New Password" name=" " />
                                </div>
                                <Link to="/login">
                                    <button type="submit" className="main-btn btn-filled mt-20 login-btn mb-3">Submit</button>
                                </Link>
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Content;
