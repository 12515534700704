import React, { Component, Fragment } from 'react';
import Shopinfo from './Shopinfo';
// import Shoprelated from '../../layouts/Shoprelated';

class Content extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Fragment>
                <Shopinfo counterIncrease={this.props.counterIncrease}/>
                {/* <Shoprelated/> */}
            </Fragment>
        );
    }
}

export default Content;