import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/img/cart/1.jpg';
import img2 from '../../assets/img/cart/2.jpg';
// import img3 from '../../assets/img/cart/3.jpg';
// import img4 from '../../assets/img/cart/4.jpg';
import Cookies from 'universal-cookie';
// import axios from 'axios';
import { addCartList } from "../../slices/customerSlice";
import { connect } from 'react-redux';

// Cart loop
const cartposts = [
    { img: img1, title: 'Oak Wood Cutting Board', price: '2x 10,000$' },
    { img: img2, title: 'Oak Wood Cutting Board', price: '2x 10,000$' },
];

class Canvas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            classmethod: false,
            togglemethod: false,
            togglecart: false
        };
        this.addClass = this.addClass.bind(this);
        this.removeClass = this.removeClass.bind(this);
        this.toggleClass = this.toggleClass.bind(this);
        this.toggleCartm = this.toggleCartm.bind(this);
    }


    addClass() {
        this.setState({
            classmethod: true
        });
    }

    removeClass() {
        this.setState({
            classmethod: false
        });
    }
    toggleClass() {
        this.setState({
            togglemethod: !this.state.togglemethod
        });
    }
    toggleCartm() {
        this.setState({
            togglecart: !this.state.togglecart
        });
    }

    componentDidMount() {
        const isLoggedIn = JSON.parse(localStorage.getItem('userData'));
        if (isLoggedIn) {
            this.fetchData(isLoggedIn.userId);
        } else {
            this.getCookiesCartList();
        }
    }
    // componentWillReceiveProps() {
    //     console.log(this.props.data)
    //     // if (this.props.classmethod) {
    //     const isLoggedIn = JSON.parse(localStorage.getItem('userData'));
    //     if (isLoggedIn) {
    //         this.fetchData(isLoggedIn.userId);
    //     } else {
    //         this.getCookiesCartList();
    //     }
    //     // }      
    // }

    fetchData = async (userIdControl) => {
        try {
            if (!userIdControl) userIdControl = '1';
            const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/cart/find-by-user-id/${userIdControl}`);
            const data = await response.json();
            let totalPriceDtl = 0;
            if (data && data[0] != undefined) {
                data.forEach(element => {
                    element['totalMultiplyPrice'] = Number(element.product_price) * Number(element.quantity);
                    totalPriceDtl = totalPriceDtl + element.totalMultiplyPrice;
                });

                this.props.addCartList(data);
            }else{
                this.props.addCartList([]);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            this.props.addCartList([]);
        }
    };

    getCookiesCartList() {
        const cookies = new Cookies();
        let cartItems = cookies.get('cartItems') || [];
        let idControl = '';
        cartItems.forEach(element => {
            idControl = idControl + ',' + element.productId;
        });
        // this.setState({ cartItems });
        if (cartItems.length > 0) {
            this.getProductListById(idControl.slice(1, idControl.length), cartItems);
        } else {
            this.setState({ data: [] });
        }
    }

    getProductListById = async (idControl, cartItems) => {
        const response = await fetch(process.env.REACT_APP_API_BASE_URL +`/api/product/get_product_detail?cartList=${idControl}`, {
            method: 'POST',
            // body: apiData
        });
        const dataList = await response.json();
        let totalPriceDtl = 0;
        dataList.forEach(element => {
            let quantityControl = cartItems?.filter((data) => {
                return data.productId == element.product_id
            });
            element['totalMultiplyPrice'] = Number(element.product_price) * Number(quantityControl[0]?.quantity);
            totalPriceDtl = totalPriceDtl + element.totalMultiplyPrice;
        });
        this.props.addCartList(dataList);
    }


    render() {
        let totalPriceDtl = 0;
        this.props.data.forEach(element => {
            totalPriceDtl = totalPriceDtl + element.totalMultiplyPrice;
        });
        totalPriceDtl = totalPriceDtl.toFixed(2);

        return (
            <Fragment>
                {/* Search Widget */}
                <div className="widget search-widget">
                    <h5 className="widget-title">Search room</h5>
                    <form action="#">
                        <input type="text" placeholder="Search your keyword..." />
                        <button type="submit"><i className="far fa-search" />
                        </button>
                    </form>
                </div>
                {/* About Widget */}
                <div className="widget about-widget">
                    <h5 className="widget-title">Cart</h5>
                </div>
                <div className="widget">
                    <div className="custom-cart-btn-area">
                        <div className="custom-cart-item actions">
                            <Link to="/checkout">
                                <button type="button" className="main-btn btn-filled">Checkout</button>
                            </Link>
                            <Link to="/cart">
                                <button type="button" className="main-btn btn-borderd">View Cart</button>
                            </Link>
                        </div>
                        <div className="custom-cart-item">
                            <h4>Subtotal</h4>
                            <h4>{totalPriceDtl}</h4>
                        </div>
                    </div>
                </div>
                <div className='widget'>
                    <ul className="cart-items-box">
                        {this.props.data?.map((item, i) => (
                            <li key={i} className="custom-cart-item">
                                <div className="img">
                                    <img src={item.product_parent_image} alt="img" />
                                </div>
                                <div className="content">
                                    <h5><Link to="#">{item.product_name}</Link></h5>
                                    <p>{item.totalMultiplyPrice}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state.customers
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addCartList: (data) => dispatch(addCartList(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
// export default Canvas;