import React, { Component } from 'react';
import { Link } from 'react-router-dom'

const navigationmenu = [
    {
        id: 1,
        linkText: 'Home',
        link: '/'
    },
    {
        id: 2,
        linkText: 'Gold',
        child: true,
        submenu: [
            {
                id: 21,
                link: '',
                linkText: 'Rings'
            },
            {
                id: 22,
                link: '',
                linkText: 'Earrings'
            },
            {
                id: 23,
                link: '',
                linkText: 'Bracelets'
            },
            {
                id: 24,
                link: '',
                linkText: 'Pendants'
            },
            {
                id: 25,
                link: '',
                linkText: 'Necklaces'
            },
            {
                id: 26,
                link: '',
                linkText: 'Daily Wears'
            },
        ]
    },
    {
        id: 3,
        linkText: 'Platinum',
        child: true,
        submenu: [
            {
                id: 31,
                link: '',
                linkText: 'Rings'
            },
            {
                id: 32,
                link: '',
                linkText: 'Earrings'
            },
            {
                id: 33,
                link: '',
                linkText: 'Bracelets'
            },
            {
                id: 34,
                link: '',
                linkText: 'Pendants'
            },
            {
                id: 35,
                link: '',
                linkText: 'Necklaces'
            },
            {
                id: 36,
                link: '',
                linkText: 'Daily Wears'
            },
        ]
    },
    {
        id: 4,
        linkText: 'Diamond',
        child: true,
        submenu: [
            {
                id: 41,
                link: '',
                linkText: 'Rings'
            },
            {
                id: 42,
                link: '',
                linkText: 'Earringss'
            },
            {
                id: 43,
                link: '',
                linkText: 'Bracelets'
            },
            {
                id: 44,
                link: '',
                linkText: 'Pendants'
            },
            {
                id: 45,
                link: '',
                linkText: 'Necklaces'
            },
            {
                id: 46,
                link: '',
                linkText: 'Daily Wears'
            },
        ]
    },
    {
        id: 5,
        linkText: 'Silver',
        child: true,
        submenu: [
            {
                id: 51,
                link: '',
                linkText: 'Rings'
            },
            {
                id: 52,
                link: '',
                linkText: 'Earringss'
            },
            {
                id: 53,
                link: '',
                linkText: 'Bracelets'
            },
            {
                id: 54,
                link: '',
                linkText: 'Pendants'
            },
            {
                id: 55,
                link: '',
                linkText: 'Necklaces'
            },
            {
                id: 56,
                link: '',
                linkText: 'Daily Wears'
            },
        ]
    },
    {
        id: 6,
        linkText: 'Gifting',
        child: true,
        submenu: [
            {
                id: 61,
                link: '',
                linkText: 'Birthday Gifts'
            },
            {
                id: 62,
                link: '',
                linkText: 'Wedding Gifts'
            },
            {
                id: 63,
                link: '',
                linkText: 'Anniversary Gifts'
            },
            {
                id: 64,
                link: '',
                linkText: 'Self Gifts'
            },
            {
                id: 55,
                link: '',
                linkText: 'Personalized Gifts'
            },
        ]
    },
    {
        id: 7,
        linkText: 'Milli Harvest',
        link: '/milli-harvest'
    },
    {
        id: 8,
        linkText: 'Shop by Store',
        link: '/store'
    },
    {
        id: 9,
        linkText: 'Wish List',
        link: '/wishlist'
    },
    {
        id: 9,
        linkText: 'Cart',
        link: ''
    },
    {
        id: 9,
        linkText: 'About',
        link: '/about-us'
    },
]
class Mobilemenu extends Component {
    getNextSibling = function (elem, selector) {
        // Get the next sibling element
        var sibling = elem.nextElementSibling;
        // If there's no selector, return the first sibling
        if (!selector) return sibling;
        // If the sibling matches our selector, use it
        // If not, jump to the next sibling and continue the loop
        while (sibling) {
            if (sibling.matches(selector)) return sibling;
            sibling = sibling.nextElementSibling
        }
    }
    triggerChild = (e) => {
        let subMenu = '';
        subMenu = (this.getNextSibling(e.target, '.sub-menu') !== undefined) ? this.getNextSibling(e.target, '.sub-menu') : null;
        if (subMenu !== null && subMenu !== undefined && subMenu !== '') {
            subMenu.classList = subMenu.classList.contains('d-block') ? 'sub-menu' : 'sub-menu d-block';
        }
    }
    render() {
        return (
            <ul className="sigma-main-menu">
                {navigationmenu.length > 0 ? navigationmenu.map((item, i) => (
                    <li key={i} className={`menu-item ${item.child ? 'menu-item-has-children' : ''} `} onClick={this.triggerChild}>
                        {item.child ? <Link onClick={e => e.preventDefault()} to="/"> {item.linkText} </Link> : <Link to={item.link}> {item.linkText} </Link>}
                        {item.child ?
                            <ul className="sub-menu" role="menu">
                                {item.submenu.map((sub_item, i) => (
                                    <li key={i} className={`menu-item ${sub_item.child ? 'menu-item-has-children' : ''} `}>
                                        {sub_item.child ? <Link onClick={e => e.preventDefault()} to="/"> {sub_item.linkText} </Link> : <Link to={sub_item.link}> {sub_item.linkText} </Link>}
                                        {sub_item.submenu ?
                                            <ul className="sub-menu">
                                                {sub_item.submenu.map((third_item, i) => (
                                                    <li className="menu-item" key={i}><Link
                                                        to={third_item.link}>{third_item.linkText}</Link>
                                                    </li>
                                                ))}
                                            </ul> : null}
                                    </li>
                                ))}
                            </ul>
                            : null
                        }
                    </li>
                )) : null}
            </ul >
        );
    }
}

export default Mobilemenu;