import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import img1 from '../../../assets/img/shop/cart-1.png';
import img2 from '../../../assets/img/shop/cart-2.png';
import img3 from '../../../assets/img/shop/cart-3.png';

const orderlists = [
    { img: img1, title: 'Product1', id: 'b123jhk4h', date: '12-Sep-2022', status: 'Delivered' },
    { img: img2, title: 'Product2', id: 'b673juyk4h', date: '14-Sep-2022', status: 'In transit' },
    { img: img3, title: 'Product3', id: 'Q123jh4h', date: '12-Sep-2022', status: 'Cancelled' },
    { img: img1, title: 'Product4', id: 'R444lo98', date: '20-Sep-2022', status: 'Delivered' },
];


class Content extends Component {
    constructor(props) {
        super(props);
        this.state={
            users:'',
            userDetail:[],
            userForm:[{user_name:'',user_email:''}],
        }
        this.eventHandler = this.eventHandler.bind(this);
      }
      eventHandler(e){
        this.setState({userForm:{...this.state.userForm,[e.target.name]:e.target.value}},()=>{
            console.log(this.state.userForm)
        })
      }
    // eventHandler(e){
    //   this.setState((data)=>({
    //     userForm:{
    //         ...data.userForm,[e.target.name]:e.target.value
    //     }
    //   }),()=>{console.log(this.state.userForm);})
    // }
    async profileDetails(){
        const user=JSON.parse(localStorage.getItem('userData'))
        console.log("user",user)
      
        const userId = user.userId;
        console.log("ID IS",userId)
        this.setState({users:userId}, () => {
        console.log("State updated:", this.state.users)})
       
       
        const api = await fetch(process.env.REACT_APP_API_BASE_URL+"/api/users/user-details/"+userId);
        const data = await api.json();
        console.log(data.user)
        this.setState({userDetail:data.user},()=>{
            console.log('user all',this.state.userDetail)
        })
    }
    componentDidMount(){
        this.profileDetails();
    }
    render() {
        return (
            <section className="account-sec pt-120 pb-120">
                <Tab.Container defaultActiveKey="profile">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <div className="account-tabs">
                                    <Nav variant="tabs" className="flex-column border-0">
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="dashboard">
                                                Dashboard
                                            </Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item>
                                            <Nav.Link eventKey="profile">
                                                Profile
                                                {/* <Link to="#" className="btn-link text-white"><i className='fa fa-pen mr-1 text-white'></i>Edit</Link> */}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="orders">
                                                Orders
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="addresses">
                                                Payments
                                            </Nav.Link>
                                        </Nav.Item>
                                        {/* <Nav.Item>
                                            <Nav.Link eventKey="addresses">
                                                Addresses
                                            </Nav.Link>
                                        </Nav.Item> */}
                                        <Nav.Item>
                                            <Nav.Link eventKey="acdetails">
                                                {/* Account Details */}
                                                Edit Profile
                                                <Link to="#" className="btn-link text-white"><i className='fa fa-pen mr-1 text-white'></i></Link>

                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link className="logout">
                                                <i className="btn-link fal fa-power-off" /> Logout
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </div>

                            <div className="col-lg-8">

                                <Tab.Content>
                                    <Tab.Pane eventKey="profile">
                                        <div className="address-content row">
                                        <div className="col-12 extra-info mb-30">
                                            <div className="billing-info">
                                                <h6>Name</h6>
                                                <p>{this.state.userDetail.user_name}</p>
                                            </div>                                               
                                        </div>

                                        <div className="col-12 extra-info mb-30">
                                            <div className="billing-info">
                                                <h6>E-mail</h6>
                                                <p>{this.state.userDetail.user_email}</p>
                                            </div>                                               
                                        </div>



                                            {/* <div className="col-12 extra-info mb-30">
                                                <div className="billing-info">
                                                    <h6>Name</h6>
                                                    <p>Moses PK</p>
                                                </div> */}
                                                {/* <Link to="#" className="btn-link"><i className='fa fa-pen mr-1'></i>Edit</Link> */}
                                            {/* </div> */}

                                            {/* <div className="col-6 extra-info mb-30">
                                                <div className="billing-info">
                                                    <h6>Mobile Number</h6>
                                                    <p>+91 1234567890</p>
                                                </div> */}
                                                {/* <Link to="#" className="btn-link"><i className='fa fa-pen mr-1'></i>Edit</Link> */}
                                            {/* </div> */}
                                            {/* <div className="col-lg-6 extra-info mb-30">
                                                <div className="billing-info">
                                                    <h6>E-mail</h6>
                                                    <p>Moses@gmail.com</p>
                                                </div> */}
                                                {/* <Link to="#" className="btn-link"><i className='fa fa-pen mr-1'></i>Edit</Link> */}
                                            {/* </div> */}
                                            {/* <div className="col-lg-6 extra-info mb-30">
                                                <div className="billing-info">
                                                    <h6>Birthday</h6>
                                                    <p>dd/mm/yy</p>
                                                </div> */}
                                                {/* <Link to="#" className="btn-link"><i className='fa fa-pen mr-1'></i>Edit</Link> */}
                                            {/* </div> */}
                                            {/* <div className="col-6 extra-info mb-30">
                                                <div className="billing-info">
                                                    <h6>Anniversary</h6>
                                                    <p>dd/mm/yy</p>
                                                </div> */}
                                                {/* <Link to="#" className="btn-link"><i className='fa fa-pen mr-1'></i>Edit</Link> */}
                                            {/* </div> */}
                                            {/* <div className="col-12 extra-info mb-30">
                                                <div className="billing-info">
                                                    <h6>Billing Address</h6>
                                                    <p>Billing Address</p>
                                                </div> */}
                                                {/* <Link to="#" className="btn-link"><i className='fa fa-pen mr-1'></i>Edit</Link> */}
                                            {/* </div> */}
                                            {/* <div className="col-12 extra-info mb-30">
                                                <div className="billing-info">
                                                    <h6>Shipping Address</h6>
                                                    <p>Shipping Address</p>
                                                </div> */}
                                                {/* <Link to="#" className="btn-link"><i className='fa fa-pen mr-1'></i>Edit</Link> */}
                                            {/* </div> */}
                                            {/* <div className="extra-info">
                                                <div className="shipping-info">
                                                    <h3>Shipping Address</h3>
                                                    <p>You have not Setup this Type of Address Yet.</p>
                                                 </div>
                                                <Link to="#" className="btn-link">Add</Link>
                                            </div> */}
                                        </div>

                                    </Tab.Pane>

                                    {/* <Tab.Pane eventKey="orders">
                                        <Nav.Item className='d-flex'>
                                            <Nav.Link eventKey="order">
                                                <h6>Active Orders</h6>
                                            </Nav.Link>
                                            <Nav.Link eventKey="order">
                                                <h6>Completed Orders</h6>
                                            </Nav.Link>
                                            <Nav.Link eventKey="order">
                                                <h6>Cancelled Orders</h6>
                                            </Nav.Link>
                                        </Nav.Item>

                                    </Tab.Pane> */}

                                    <Tab.Pane eventKey="dashboard">
                                        {/* <div className="dashboard-content">
                                            <p className="text-white mb-30">Hello <b>John Benjamin</b> (not <b>John Benjamin</b>?
                                                <Link to="/">Log Out</Link>)
                                            </p>
                                            <p className="text-white">From Your Account Dashboard You can View Your <Link to="#">Recent Orders</Link>, Manage Your<Link to="#">Shipping and Billing Addresses</Link> and <Link to="#">Edit Your Password and Account Details</Link></p>
                                        </div> */}

                                    </Tab.Pane>

                                    <Tab.Pane eventKey="orders">
                                        <div className='order-head ml-5'>
                                            <Link to="/active-orders"><h3>Active Orders</h3></Link>
                                        </div>
                                        <div className='order-head mt-5 ml-5'>
                                            <Link to="/completed-orders"><h3>Delivered Orders</h3></Link>
                                        </div>
                                        <div className='order-head mt-5 ml-5'>
                                            <Link to="/custom-orders"><h3>Customized Orders</h3></Link>
                                        </div>
                                        <div className='order-head mt-5 ml-5'>
                                            <Link to="/cancelled-orders"><h3>Cancelled Orders</h3></Link>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="">
                                        <div>
                                            {/* <div className="content-heading mb-50">
                                                <h3>My Orders</h3>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                            </div> */}
                                            <div className="order-table">
                                                <table className="table cw-cart-table mb-0">
                                                    <thead>
                                                        <tr>
                                                            {/* <th /> */}
                                                            <th scope="col" className="product-name">My Order</th>
                                                            <th scope="col" className="product-qty">Order ID</th>
                                                            <th scope="col" className="product-price">Order Date</th>
                                                            <th scope="col" className="product-status">Order Status</th>
                                                            <th scope="col" className="product-details"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {orderlists.map((item, i) => (
                                                            <tr key={i}>
                                                                {/* <td className="product-remove text-center cw-align">
                                                                    <Link to="#"><i className="fas fa-times" /></Link>
                                                                </td> */}
                                                                <td data-title="Product" className="has-title">
                                                                    <div className="product-thumbnail">
                                                                        <img src={item.img} alt="product_thumbnail" />
                                                                    </div>
                                                                    <Link to="/order-details">{item.title}</Link>
                                                                </td>
                                                                <td className="product-price text-white cw-align has-title" data-title="Order ID">#{item.id}</td>
                                                                <td className="product-price text-white cw-align has-title" data-title="Order Date">{item.date}</td>
                                                                <td className="product-price text-white cw-align has-title" data-title="Order Status">{item.status}</td>
                                                                <td data-title="Actions" className="has-title">
                                                                    <Link to="/order-details" className="main-btn btn-filled">View details</Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="downloads">
                                        {/* <div className="content-heading download-content">
                                            <h3>You Haven't Downloaded Any Product</h3>
                                            <p>You still havent saved any products yet, Go back to the products page and check some of your favorite products</p>
                                            <Link to="/shop-left" className="main-btn btn-outline mt-20">Browse Products</Link>
                                        </div> */}

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="addresses">
                                        {/* <div className="address-content">
                                            <p className="mb-30">The Following Address will Be Used on Checkout Page by Default</p>
                                            <div className="extra-info mb-30">
                                                <div className="billing-info">
                                                    <h3>Billing Address</h3>
                                                    <p>John Benjamin</p>
                                                </div>
                                                <Link to="#" className="btn-link">Edit</Link>
                                            </div>
                                            <div className="extra-info">
                                                <div className="shipping-info">
                                                    <h3>Shipping Address</h3>
                                                    <p>You have not Setup this Type of Address Yet.</p>
                                                </div>
                                                <Link to="#" className="btn-link">Add</Link>
                                            </div>
                                        </div> */}

                                    </Tab.Pane>
                                    <Tab.Pane eventKey="acdetails">
                                        <div className="profile-content">
                                            {/* <div className="content-heading mb-50">
                                                <h3>Welcome Back</h3>
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                                            </div> */}
                                            <form method="post">
                                                <div className="row">
                                                    <div className="col-lg-6 input-group input-group-two mb-20">
                                                        <label>First Name
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input type="text" name="user_name" placeholder={this.state.userDetail.user_name} value={this.state.userForm.user_name} onChange={this.eventHandler} />
                                                    </div>
                                                    <div className="col-lg-6 input-group input-group-two mb-20">
                                                        <label>Last Name
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input type="text" placeholder="Benjamin" name="lname" />
                                                    </div>
                                                    {/* <div className="col-12 input-group input-group-two mb-20">
                                                        <label>Display Name
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input type="text" placeholder="John Benjamin" name="dname" />
                                                        <p className="mt-2">This is how your Name will be Displayed in the Account Section and Reviews..</p>
                                                    </div> */}
                                                    <div className="col-lg-6 input-group input-group-two mb-20">
                                                        <label>Email Address
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input type="mail" name="user_email" placeholder={this.state.userDetail.user_email} value={this.state.userForm.user_email} onChange={this.eventHandler} />
                                                    </div>
                                                    <div className="col-lg-6 input-group input-group-two mb-20">
                                                        <label>Mobile Number
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <input type="text" placeholder="Number" name="email" />
                                                    </div>
                                                    <div className="col-lg-4 input-group input-group-two mb-20">
                                                        <label>Current Password
                                                        </label>
                                                        <input type="text" placeholder="Current Password" name="c-password" />
                                                    </div>
                                                    <div className="col-lg-4 input-group input-group-two mb-20">
                                                        <label>New Password
                                                        </label>
                                                        <input type="text" placeholder="New Password" name="n-password" />
                                                    </div>
                                                    <div className="col-lg-4 input-group input-group-two mb-20">
                                                        <label>Confirm New Password
                                                        </label>
                                                        <input type="text" placeholder="Confirm Password" name="c-password" />
                                                    </div>
                                                </div>
                                                <button  type="submit" className="main-btn btn-filled">Save Changes</button>
                                            </form>
                                        </div>

                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </div>
                </Tab.Container>
            </section>
        );
    }
}

export default Content;