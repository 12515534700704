import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import breadcrumbimg from '../../assets/img/bg/mapbnr.png'

class Breadcrumbs extends Component {
    render() {
        return (
            <section className="breadcrumb-area" style={{ backgroundImage: "url(" + breadcrumbimg + ")" }}>
                <div className="container">
                    <div className="breadcrumb-text">
                        <span></span>
                        <h2 className="page-title"> Find a Store near you</h2>
                        <span>Find a Milli store in your locality, the Milli family is growing everyday</span>
                        <div className="widget search-widget">
                            <form action="#">
                                <input type="text" placeholder="Enter your Pincode or City" />
                                <button type="submit"><i className="far fa-search" />
                                </button>
                            </form>
                        </div>
                        <ul className="breadcrumb-nav">
                            <li><Link to="/">Home</Link></li>
                            <li className="active">{this.props.breadcrumb.pagename}</li>
                        </ul>
                    </div>
                </div>
            </section>
        );
    }
}

export default Breadcrumbs;